import { Injectable } from '@angular/core'
import { environment } from '@consumer/environments/environment'
import { E11BackdropService } from '@engineering11/ui-lib/e11-backdrop'
import { isNotNil } from '@engineering11/utility'
import { e11Log, toE11Error } from '@engineering11/web-api-error'
import { Timestamp } from '@engineering11/web-api-firebase'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators'
import { ISD_ERRORS, ISellerApplication, ISellerApplicationDto, LocalStorageService, SellerApplicationStatus, getCustomerKey$ } from 'shared-lib'
import { SellerApplicationRepository } from '../repository/seller-application.repository'

@Injectable({ providedIn: 'root' })
export class SellerService {
  customerKey$ = this.store.pipe(getCustomerKey$).pipe(filter(isNotNil))

  private restClient: RestApiClient

  constructor(
    private localStorageService: LocalStorageService,
    private backdropService: E11BackdropService,
    private store: Store,
    private sellerApplicationRepository: SellerApplicationRepository
  ) {
    this.restClient = new RestApiClient({
      baseUrl: environment.services.seller,
      token: () => localStorageService.getItem('token')!,
    })
  }

  sendSellerApplication(sellerApplication: ISellerApplicationDto) {
    return this.restClient.post('application', sellerApplication).toPromise()
  }

  getApplicationStatus(): Observable<SellerApplicationStatus> {
    return this.customerKey$.pipe(
      switchMap(customerKey => {
        // if (isSeller(user)) return of(SellerApplicationStatus.Accepted)
        return this.sellerApplicationRepository
          .listenByCustomerKey(customerKey)
          .pipe(map(application => (application ? application.status : SellerApplicationStatus.NotFound)))
      }),
      distinctUntilChanged()
    )
  }

  getSellerApplication(): Observable<Timestamp<ISellerApplication> | undefined> {
    return this.customerKey$.pipe(
      e11Log({ key: 'getSellerApplication' }),
      switchMap(customerKey => this.sellerApplicationRepository.listenByCustomerKey(customerKey)),
      distinctUntilChanged()
    )
  }

  async registerPaymentAccount() {
    this.backdropService.backdropDisplay(true, 'dark')
    const returnURL = new URL('shop', location.origin)
    const refreshURL = new URL('shop?registration=true', location.origin)
    try {
      const response = await this.getPaymentAccountRegistrationLink(returnURL.toString(), refreshURL.toString())
      if (response && response.url) {
        const { url } = response
        location.href = url
      }
    } catch (err) {
      throw toE11Error(err, {
        type: ISD_ERRORS.SELLER_REGISTRATION_FAILED,
        message: 'Seller Registration Failed',
        title: 'Seller Registration Failed',
      })
    } finally {
      this.backdropService.backdropDisplay(false, '')
    }
  }

  getPaymentAccountRegistrationLink(returnURL: string, refreshURL: string) {
    return this.restClient
      .post<{ url: string } | null>('application/registration-link', {
        returnURL,
        refreshURL,
      })
      .pipe(map(response => response.data))
      .toPromise()
      .then(response => response!)
  }

  getSellerPortalLink() {
    return this.restClient
      .get<{ url: string }>(`seller/account/link`)
      .pipe(map(response => response.data))
      .toPromise()
      .then(response => response!)
  }
}
