import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'modal-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class ModalPrivacyPolicyComponent {
  @ViewChild('modalPrivacy') modalPrivacy: any
  @ViewChild('privacyPolicy') privacyPolicy: any
  @Output() closeModal = new EventEmitter()

  _status: boolean
  get status(): boolean {
    return this._status
  }
  @Input() set status(value: boolean) {
    this._status = value
    if (this._status) this.modalPrivacy.open()
    else this.modalPrivacy?.close()
  }

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}
}
