<div class="e11-w-full e11-px-6 md-e11-w-2/3 lg:e11-w-1/2 e11-mx-auto e11-pt-8 e11-pb-16">
  <e11-panel>
    <div class="e11-text-center">
      <h2 class="e11-text-center e11-text-skin-primary">
        {{ 'Create Your Account' | translate }}
      </h2>
      <p class="e11-text-sm">
        {{ 'Already have an account?' | translate }}
        <a (click)="navigateToLogin()" class="e11-cursor-pointer e11-font-medium e11-text-skin-secondary hover:e11-text-skin-secondary">
          {{ 'Sign In' | translate }}
        </a>
      </p>
    </div>
    <sdk-registration-consumer [includeDisplayName]="false" (onSubmit)="onSubmit($event)" (termsClicked)="termsClicked()"></sdk-registration-consumer>
  </e11-panel>
</div>
