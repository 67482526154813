import { Injectable } from '@angular/core'
import { environment } from '@consumer/environments/environment'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Store } from '@ngrx/store'
import { map } from 'rxjs/operators'
import { IPublicSellerInformation, LocalStorageService, getCurrentToken } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class SellerInformationService {
  private apiClient: RestApiClient = new RestApiClient({
    baseUrl: environment.services.seller,
    token: this.store.select(getCurrentToken),
  })
  constructor(private store: Store) {}

  getPublicSellerInformation(customerKey: string) {
    return this.apiClient.get<IPublicSellerInformation>(`seller/${customerKey}/information`).pipe(map(res => res.data))
  }
}
