<div class="e11-w-full e11-px-6 e11-w-full md-e11-w-2/3 lg:e11-w-1/2 e11-mx-auto e11-pt-8 e11-pb-16">
  <e11-panel>
    <div class="sm:e11-mx-auto sm:e11-w-full sm:e11-max-w-md">
      <h2 class="e11-text-center e11-text-skin-primary">
        {{ 'Sign into your account' | translate }}
      </h2>
      <p class="e11-text-center e11-text-sm e11-text-skin-light">
        {{ 'Not signed up?' | translate }}
        <a (click)="navigateToRegistration()" class="e11-cursor-pointer e11-text-skin-secondary hover:e11-text-skin-secondary">
          {{ 'Create an account' | translate }}
        </a>
      </p>
    </div>
    <auth-login (onSubmit)="onSubmit($event)" (onError)="onError($event)" (onForgottenPassword)="onForgottenPassword()"></auth-login>
  </e11-panel>
</div>
