<div *ngIf="state" class="e11-flex e11-flex-wrap e11-gap-4 e11-text-sm">
  <ng-container *ngFor="let item of state.items">
    <ng-container *ngFor="let refinement of item.refinements">
      <div
        (click)="removeRefinement(item, refinement)"
        class="e11-flex e11-items-center e11-cursor-pointer e11-bg-skin-secondary-200 hover:e11-bg-skin-secondary-300 hover:e11-shadow-sm e11-rounded-full e11-px-4 e11-max-w-[150px]"
      >
        <span class="material-icons-outlined e11-text-sm e11-mr-1">cancel</span>
        <span class="e11-truncate e11-text-ellipsis e11-overflow-hidden">{{ refinement.label | translate }}</span>
      </div>
    </ng-container>
  </ng-container>

  <div class="e11-flex e11-gap-4" *ngIf="state.items.length">
    <ais-clear-refinements #clearRefinements class="e11-hidden"></ais-clear-refinements>
    <a (click)="clear()" class="e11-flex e11-items-center e11-cursor-pointer e11-text-blue-500 e11-underline">
      {{ 'Clear all' | translate }}
    </a>
  </div>
</div>
