import { Component, EventEmitter, Output } from '@angular/core'
import { NotificationTranslateService } from 'shared-lib'
import { IGeoLocation, LocationService } from '@engineering11/maps-web'
import { isE11Error } from '@engineering11/web-api-error'
import { NGXLogger } from 'ngx-logger'

@Component({
  selector: 'app-my-location-button',
  templateUrl: './my-location-button.component.html',
  styleUrls: ['./my-location-button.component.scss'],
})
export class MyLocationButtonComponent {
  loading = false

  @Output() myLocation = new EventEmitter<IGeoLocation>()

  constructor(private locationService: LocationService, private notificationService: NotificationTranslateService, private logger: NGXLogger) {}

  async searchByMyLocation() {
    this.loading = true
    try {
      const location = await this.locationService.getGeolocation()
      this.logger.log('my location:', location)
      this.myLocation.emit(location)
    } catch (e) {
      this.logger.error(e)
      if (isE11Error(e)) {
        this.notificationService.popNotificationMessage('Location Error', e.errorData.title, 'danger', false)
      } else {
        this.notificationService.popNotificationMessage('Location Error', 'There was an error getting your location', 'danger', false)
      }
    }
    this.loading = false
  }
}
