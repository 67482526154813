import { NgModule } from '@angular/core'

import { CommonModule } from '@angular/common'
import { SharedModule } from '../_shared/shared.module'
import { CustomerDetailComponent } from './components/customer-detail.component'
import { CustomerRoutingModule } from './customer.routing'

import { RegistrationConfigProvider } from '@consumer/app/config/registration.config'
import { AuthModule } from '@engineering11/auth-web'
import { RegistrationModule as RegistrationModuleSDK } from '@engineering11/registration-web'
import { BusinessUserManagementRestService, IUserRestService, UserModule } from '@engineering11/user-web'
import { ManageUsersComponent } from './components/manage-users/manage-users.component'
import { environment } from '@consumer/environments/environment'
import { CompanyInformationComponent } from './components/company-information/company-information.component'
import { CustomerModule as SDKCustomerModule } from '@engineering11/customer-web'
import { ConsumerCustomerConfigProvider, ConsumerCustomerPartitionConfigProvider } from '@consumer/app/config/customer.config'
import { UserConfigProvider } from '@consumer/app/config/user.config'

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SDKCustomerModule.forRoot({
      configProvider: ConsumerCustomerConfigProvider,
      partitionConfigProvider: ConsumerCustomerPartitionConfigProvider,
    }),
    CustomerRoutingModule,
    AuthModule,
    UserModule.forRoot({ configProvider: UserConfigProvider }),
    RegistrationModuleSDK.forRoot({ configProvider: RegistrationConfigProvider }),
  ],
  exports: [],
  declarations: [CustomerDetailComponent, ManageUsersComponent, CompanyInformationComponent],
  providers: [{ provide: IUserRestService, useClass: BusinessUserManagementRestService }],
})
export class CustomerModule {}
