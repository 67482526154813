import { Injectable } from '@angular/core'
import { BasePaymentConfigurationProvider, IPaymentMappingConfig } from '@engineering11/payment-web'
import { E11Logger } from '@engineering11/web-api-error'
import { Store } from '@ngrx/store'
import { getCurrentToken, getCurrentUserEmail, getCurrentUserId, getCustomerKey$ } from 'shared-lib'
import { environment } from '../../environments/environment'
import { Permission } from './permission.config'

@Injectable({ providedIn: 'root' })
export class PaymentConfigProvider extends BasePaymentConfigurationProvider {
  constructor(private store: Store, private logger: E11Logger) {
    super()
  }
  get config(): IPaymentMappingConfig {
    // console.log('PaymentConfigProvider.config', this.config.productPermissions)
    return {
      paymentsBaseURL: environment.services.product,
      userId$: this.store.pipe(getCurrentUserId),
      token$: this.store.select(getCurrentToken),
      customerBillingEmail$: this.store.pipe(getCurrentUserEmail),
      mapsApiKey: environment.firebaseConfig.apiKey,
      productPermissions: [Permission.MANAGE_USERS, Permission.SELL_ITEMS],
      customerKey$: this.store.pipe(getCustomerKey$),
    }
  }
}
