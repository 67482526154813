import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@consumer/environments/environment'
import { Observable } from 'rxjs'

@Injectable()
export class HttpServiceInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has('X-Service-Request')) {
      const authReq = req.clone({
        headers: new HttpHeaders({
          // 'Accept': 'application/json',
          'Accept-Version': '1',
          // 'X-TENANT-ID': environment.tenantId,
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
      })

      return next.handle(authReq)
    }
    return next.handle(req)
  }
}
