import { Component, Input, OnInit } from '@angular/core'
import { IProductItem } from 'shared-lib'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'item-shipping-options-radio',
  templateUrl: './item-shipping-options-radio.component.html',
  styleUrls: ['./item-shipping-options-radio.component.scss'],
})
export class ItemShippingOptionsRadioComponent implements OnInit {
  @Input() item: IProductItem
  @Input() parentForm: FormGroup

  constructor() {}

  get f() {
    return this.parentForm.controls
  }

  ngOnInit(): void {}
}
