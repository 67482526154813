// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {
  AppEnginePathBuilder,
  CloudFunctionsPathBuilder,
  Env,
  IConsumerEnvironment,
  buildTenantConfig,
  envToProjectId,
  getEnvDomain,
} from 'shared-lib'

const env: Env = 'dev'
const projectId = envToProjectId[env]

const appEngineBuilder = new AppEnginePathBuilder(env)
const cloudFunctionsBuilder = new CloudFunctionsPathBuilder(env)

export const environment: IConsumerEnvironment = {
  production: false,
  env,
  firebaseConfig: {
    apiKey: 'AIzaSyDTlFzt2aH-uBjKWXOmV0Bq4pTl0lay5_Q',
    appId: '1:111115542778:web:8ae0a3d40cbbb5b18f6943',
    authDomain: `${projectId}.firebaseapp.com`,
    measurementId: 'G-51GCMZGY8B',
    messagingSenderId: '111115542778',
    projectId,
    storageBucket: `${projectId}.appspot.com`,
  },
  services: {
    auth: appEngineBuilder.build('auth'),
    cloudFunctions: cloudFunctionsBuilder.build(),
    commerce: appEngineBuilder.build('commerce'),
    content: appEngineBuilder.build('content'),
    customer: appEngineBuilder.build('customer'),
    events: appEngineBuilder.build('events'),
    files: appEngineBuilder.build('files'),
    integration: appEngineBuilder.build('integration'),
    messaging: appEngineBuilder.build('messaging'),
    notifications: appEngineBuilder.build('notifications'),
    product: appEngineBuilder.build('product'),
    productItem: appEngineBuilder.build('productItem'),
    registration: appEngineBuilder.build('registration'),
    seller: appEngineBuilder.build('seller'),
    user: appEngineBuilder.build('user'),
  },
  _business_EmailActionRoute: 'http://localhost:4610/#/auth/actions',
  algoliaAppId: 'L339Q2EHNO',
  algoliaSearchKey: '9eaf645892f1baf22b6768ada1e3fe80',
  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: false, // Look to the auth guard for use of this value
  basePlatformHost: getEnvDomain(env, 'app.instockdepot.com'),
  cdnCname: getEnvDomain(env, 'content.instockdepot.com'),
  emailVerificationEmailTemplateId: '2LKYubs8e8JqMm17XrZW',
  passwordRecoveryEmailTemplateId: 'GQKsG5Wnv9sWJ9uY3aDf',
  stripePublishableKey: 'pk_test_51L1Y8bDfdeY2QXyZWiLbL4qsie9qfLOxC4jPOH1vhG4wkJ7o87UscmIjXa08nbG4pnX5kkRjhjSd1I18kXW9qEMt005MVFl4i9',
  tenant: buildTenantConfig(env),
  tenantId: 'B2C-USER-mfxv0',
  themesEnabled: true,
  v2ReCaptcha: '6LeKuIgdAAAAAJRFPaQEPG9M08LIhdPvq928o0K4',
  version: '50ba14a',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
