import { Inject, Injectable } from '@angular/core'
import { NotificationType } from '@engineering11/ui-lib/e11-notifications'
import {
  BaseE11ErrorHandler,
  E11Error,
  ErrorTrackerLevel,
  ERROR_TRACKER_TOKEN,
  IErrorTracker,
  ILogger,
  LOGGER_TOKEN,
} from '@engineering11/web-api-error'
import { NotificationTranslateService } from '../notification-translate.service'

@Injectable({ providedIn: 'root' })
export class NotificationE11ErrorHandler extends BaseE11ErrorHandler {
  errorTrackerLevel: ErrorTrackerLevel = 'info'

  constructor(
    @Inject(LOGGER_TOKEN) logger: ILogger,
    @Inject(ERROR_TRACKER_TOKEN) errorTracker: IErrorTracker,
    private notificationService: NotificationTranslateService
  ) {
    super(logger, errorTracker)
  }

  /**
   *
   * @param error
   * @param overrides overrides to the notification the user is shown
   * @returns
   */
  handleError(error: E11Error, overrides?: { title?: string; message?: string; type?: NotificationType; autoClose?: boolean }) {
    const errorData = error.errorData
    this.logger.warn('Sending Info level to Error Tracker for E11 Error: ', errorData.type, { errorData })
    const title = overrides?.title ?? 'An Error Has Occurred'
    const message = overrides?.message ?? errorData.title
    const type = overrides?.type ?? 'warning'
    const autoClose = overrides?.autoClose ?? false
    this.notificationService.popNotificationMessage(title, message, type, autoClose)
    return super.handleError(error)
  }
}
