// Framework
import { Component, OnInit } from '@angular/core'
import { AuthService } from '@engineering11/auth-web'

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit(): void {}

  logout() {
    this.authService.signOut()
  }
}
