import { Action } from '@ngrx/store'
import { AppConfig, AppListenConfig } from '../../config'

export enum ConfigActionTypes {
  onInitConfig = '[Config] on init',
  onGetStaticConfig = '[Config] on get static config',
  onGetStaticConfigSuccess = '[Config] on get static config success',
  onListenConfigChanged = '[Config] on listen config change',
}

export class OnInitConfig implements Action {
  readonly type = ConfigActionTypes.onInitConfig
  constructor(public payload: any) {}
}

export class OnGetStaticConfig implements Action {
  readonly type = ConfigActionTypes.onGetStaticConfig
  constructor() {}
}

export class OnGetStaticConfigSuccess implements Action {
  readonly type = ConfigActionTypes.onGetStaticConfigSuccess
  constructor(public payload: AppConfig) {}
}

export class OnListenConfigChanged implements Action {
  readonly type = ConfigActionTypes.onListenConfigChanged
  constructor(public payload: AppListenConfig) {}
}

export type ConfigActions = OnInitConfig | OnGetStaticConfig | OnGetStaticConfigSuccess | OnListenConfigChanged
