import { Observable, of } from 'rxjs'
import { TranslateLoader } from '@ngx-translate/core'

declare var System: System
interface System {
  import(request: string): Promise<any>
}

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    // return from(import(`../../assets/i18n/${lang}.json`)).pipe(pluck('default'))
    return of(require(`../../assets/i18n/${lang}.json`))
  }
}
