import { IUser, UserType } from '@engineering11/user-shared'
import { IAppBusinessUser, IAppConsumerUser } from '../../model/user.model'

/**
 * Ensures user is a consumer, else throws an error
 * Should remain internal to store
 * @param user
 * @returns
 */
export const _consumerOrError = (user: IUser | null): IAppConsumerUser | null => {
  if (!user) {
    return null
  }
  if (user.userType === UserType.Consumer) {
    return user
  }
  throw new Error(`User must be of type consumer, but is of type ${user.userType}`)
}

/**
 * Ensures user is a _business_, else throws an error
 * Should remain internal to store
 * @param user
 * @returns
 */
export const __business_OrError = (user: IUser | null): IAppBusinessUser | null => {
  if (!user) {
    return null
  }
  if (user.userType === UserType.Business) {
    return user
  }
  throw new Error(`User must be of type business, but is of type ${user.userType}`)
}
