import { IMultiSelectOption } from '@engineering11/ui-lib/e11-multi-select'
import { IUser } from '@engineering11/user-web'
import { IConsumerUser } from '@instockdepot/user-shared'
import { ADMINISTRATOR_ROLE, SELLER_ROLE } from '../constants'

export interface IAppConsumerUser extends IConsumerUser {
  hideSellersApplicationBanner?: boolean
}
export type IPlatformUser = IAppBusinessUser | IAppConsumerUser

export interface IAppBusinessUser extends IUser {
  department?: string
  location?: string
  companyRole?: string
}

export const UserRolesSelector: IMultiSelectOption[] = [
  {
    label: ADMINISTRATOR_ROLE,
    value: ADMINISTRATOR_ROLE,
    description:
      'The Administrator role has access to all features including user management and billing. This user will automatically get a Seller and Consumer role.',
  },
  {
    label: SELLER_ROLE,
    value: SELLER_ROLE,
    description: 'The Seller role has access to list and manage items for sale. This user will automatically get a Consumer role.',
  },
]
