import { Injectable, inject } from '@angular/core'
import { environment } from '@consumer/environments/environment'
import { AuthService } from '@engineering11/auth-web'
import { isNotNil } from '@engineering11/utility'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Store } from '@ngrx/store'
import { filter, firstValueFrom } from 'rxjs'
import { getCurrentToken } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class CustomRegistrationService {
  #store = inject(Store)

  constructor(private authService: AuthService) {}
  async regenerateCustomToken() {
    const restClient = this.restClient({ isPublicRequest: true })
    const { data } = await firstValueFrom(restClient.get<{ token: string }>('sellers/token'))
    const { res, err } = await this.authService.customTokenSignIn(data.token)
    if (err) throw err
    await res?.user?.getIdToken(true)
  }

  restClient({ isPublicRequest }: { isPublicRequest: boolean }) {
    return new RestApiClient({
      baseUrl: environment.services.registration,
      token: this.#store.select(getCurrentToken).pipe(filter(token => isPublicRequest || isNotNil(token))),
      throwNon200s: true,
    })
  }
}
