import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { from } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { ConfigService } from '../../service/config.service'
import { ListenConfigService } from '../../service/listen-config.service'
import { ConfigActionTypes, OnInitConfig, OnGetStaticConfig, OnGetStaticConfigSuccess, OnListenConfigChanged } from './config.actions'

@Injectable()
export class ConfigEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private configService: ConfigService,
    private listenConfigService: ListenConfigService
  ) {}

  ngrxOnInitEffects(): Action {
    return { type: ConfigActionTypes.onInitConfig }
  }

  onInitListenConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType<OnInitConfig>(ConfigActionTypes.onInitConfig),
      //switchMap(action => this.listenConfigService.listenMainConfiguration()),
      map(listenConfig => new OnListenConfigChanged({ maintenancePageOn: false }))
    )
  )

  onInitGetStaticConfig = createEffect(() =>
    this.actions$.pipe(
      ofType<OnInitConfig>(ConfigActionTypes.onInitConfig),
      map(action => new OnGetStaticConfig())
    )
  )

  onGetStaticConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType<OnGetStaticConfig>(ConfigActionTypes.onGetStaticConfig),
      switchMap(action => from(this.configService.getAll())),
      map(staticConfig => new OnGetStaticConfigSuccess(staticConfig))
    )
  )
}
