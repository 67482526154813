<div class="e11-pb-16 e11-w-full e11-flex e11-justify-center">
  <div class="e11-w-3/4 md:e11-w-1/2 lg:e11-w-1/3">
    <!-- Dynamic/Conditional Panel title -->
    <h1 class="e11-text-center e11-text-skin-primary e11-font-skin-century-gothic e11-mb-4">{{ 'Register with your team' | translate }}</h1>

    <e11-panel [contentClassOverrides]="'e11-px-8 e11-py-6'">
      <sdk-registration-employee
        *ngIf="registrationCode && !showError"
        [registrationCode]="registrationCode"
        (onError)="onError($event)"
        (onSubmit)="onSubmit($event)"
      ></sdk-registration-employee>
      <div class="loader" *ngIf="loading" class="active">
        <e11-loader-dots
          *ngIf="loading"
          [size]="'md'"
          [fullScreen]="true"
          [color]="'e11-bg-skin-primary-accent'"
          [hasBackdrop]="true"
          [fontColor]="'e11-text-white'"
          label="{{ 'Setting up...' | translate }}"
        ></e11-loader-dots>
      </div>
    </e11-panel>
  </div>
</div>
