import { PaymentModule } from '@engineering11/payment-web'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { TranslateModule } from '@ngx-translate/core'
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input'
import { AsideleftComponent } from './components/aside-left/aside-left.component'
import { AppFooterComponent } from './components/footer/footer.component'
import { AppHeaderSecondaryComponent } from './components/header-secondary/header-secondary.component'
import { AppHeaderUnauthenticatedComponent } from './components/header-unauthenticated/header-unauthenticated.component'
import { AppHeaderComponent } from './components/header/header.component'
import { CloudflareStreamModule } from '@cloudflare/stream-angular'
import { E11InputErrorsModule } from '@engineering11/ui-lib/e11-input-errors'
import { LetDirective, PushPipe } from '@ngrx/component'
import { ModalPrivacyPolicyComponent } from './components/modals/privacy-policy/privacy-policy.component'
import { ModalTermsOfUseComponent } from './components/modals/terms-of-use/terms-of-use.component'
import { SharedLibModule } from 'shared-lib'
import { GoogleMapsModule } from '@angular/google-maps'
import { ConversationCustomDataProvider, MessagingConfigFromApp } from '@consumer/app/config/messaging.config'
import { PaymentConfigProvider } from '@consumer/app/config/payment.config'
import { CurrentRefinementsComponent } from '@consumer/app/modules/_shared/components/algolia/current-refinements/current-refinements.component'
import { SideoverContainerComponent } from '@consumer/app/modules/_shared/components/sideover-container/sideover-container.component'
import { environment } from '@consumer/environments/environment'
import { MessagingConfigurationProvider, MessagingModule } from '@engineering11/messaging-web'
import { WebUtilityModule } from '@engineering11/web-utilities'
import { NgAisModule } from '@engineering11/angular-instantsearch'
import { UiAlgCardComponent } from './components/algolia/ui-algolia-card/ui-alg-card.component'
import { FormZipCodeComponent } from './components/form-zip-code/form-zip-code.component'
import { ItemDetailComponent } from './components/item-detail/item-detail.component'
import { ListItemComponent } from './components/list-item/list-item.component'
import { ListComponent } from './components/list/list.component'
import { FormModalContainerComponent } from './components/modal-container/form-modal-container.component'
import { UiTemplate } from './components/modal-container/ui-template'
import { MyLocationButtonComponent } from './components/my-location-button/my-location-button.component'
import { PaymentBannerComponent } from './components/payment-banner/payment-banner.component'
import { SearchBarComponent } from './components/search-bar/search-bar.component'
import { CustomerClientModule } from '@engineering11/customer-web'
import { ItemShippingOptionsRadioComponent } from '@consumer/app/modules/_shared/components/item-shipping-options-radio/item-shipping-options-radio.component'
import { PublicPrivacyComponent } from './components/modals/privacy-policy/privacy.component'
import { DisplayTermsOfUseComponent } from './components/modals/terms-of-use/display-term.component'

const DeclareExportComponents = [
  AppFooterComponent,
  AppHeaderComponent,
  AppHeaderSecondaryComponent,
  AppHeaderUnauthenticatedComponent,
  AsideleftComponent,
  SearchBarComponent,
  CurrentRefinementsComponent,
  ListComponent,
  ListItemComponent,
  UiAlgCardComponent,
  ModalPrivacyPolicyComponent,
  ModalTermsOfUseComponent,
  FormModalContainerComponent,
  UiTemplate,
  FormZipCodeComponent,
  MyLocationButtonComponent,
  SideoverContainerComponent,
  ItemDetailComponent,
  PaymentBannerComponent,
  ItemShippingOptionsRadioComponent,
  PublicPrivacyComponent,
  DisplayTermsOfUseComponent,
  // GoogleMapsModule
]

@NgModule({
  declarations: [...DeclareExportComponents],
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule, // For header login modal
    UiComponentLibraryModule,
    SharedLibModule.forRoot(environment),
    TranslateModule,
    LetDirective,
    PushPipe,
    CloudflareStreamModule,
    NgAisModule,
    WebUtilityModule,
    E11InputErrorsModule.forRoot({
      additional: {
        invalidDate: 'Please enter a valid date',
        invalidStartDate: 'Must be before end date',
        invalidEndDate: 'Must be after start date',
      },
    }),
    MessagingModule.forRoot({
      config: {
        provide: MessagingConfigurationProvider,
        useClass: MessagingConfigFromApp,
      },
      conversationCustomDataProvider: ConversationCustomDataProvider,
    }),
    PaymentModule.forRoot({
      configProvider: PaymentConfigProvider,
      stripePublishableKey: environment.stripePublishableKey,
    }),
    CustomerClientModule,
    NgxIntlTelInputModule,
    GoogleMapsModule,
  ],
  exports: [...DeclareExportComponents, UiComponentLibraryModule, TranslateModule, SharedLibModule, NgAisModule, MessagingModule, PaymentModule],
})
export class SharedModule {}
