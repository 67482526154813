import { ISellerApplication, SellerApplicationStatus } from 'shared-lib'
import { SellerApplicationActions, SellerApplicationActionTypes } from './seller-application.actions'
import { Timestamp } from '@engineering11/web-api-firebase'

export interface State {
  loading: boolean
  loadingSubmit: boolean
  applicationStatus: SellerApplicationStatus | null
  application: Timestamp<ISellerApplication> | null
}

export const initialState: State = {
  loading: false,
  loadingSubmit: false,
  applicationStatus: null,
  application: null,
}

export function reducer(state = initialState, action: SellerApplicationActions) {
  switch (action.type) {
    case SellerApplicationActionTypes.getSellerApplication:
      return { ...state, loading: true }
    case SellerApplicationActionTypes.getSellerApplicationSuccess:
      return {
        ...state,
        loading: false,
        application: action.payload.application,
        applicationStatus: action.payload.application ? action.payload.application.status : SellerApplicationStatus.NotFound,
      }
    case SellerApplicationActionTypes.sendSellerApplication:
      return { ...state, loadingSubmit: true }
    case SellerApplicationActionTypes.sendSellerApplicationSuccess:
    case SellerApplicationActionTypes.sendSellerApplicationFailure:
      return { ...state, loadingSubmit: false }
    default:
      return state
  }
}
