import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { SellerInformationService } from '@consumer/app/services/seller-information.service'
import { IProductItem, IPublicSellerInformation, ItemViewContext, LocalStorageService } from 'shared-lib'
import { IUser } from '@engineering11/user-shared'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss'],
})
export class ItemDetailComponent implements OnInit {
  @Input() context: ItemViewContext = ItemViewContext.Listing
  @Input() item!: IProductItem

  @Output() contactSellerClicked = new EventEmitter()
  @Output() previewClick = new EventEmitter()

  currentUser!: IUser
  seller?: IPublicSellerInformation
  destroy$ = new Subject<boolean>()
  zoom = 11
  messageDisabled = false
  contactSellerDisabled = false
  itemViewContext = ItemViewContext
  // ! We should type this correctly
  contactSellerForm: UntypedFormGroup = this.fb.group({
    quantity: [1, Validators.min(1)],
    shippingOption: [''],
  })

  constructor(
    private sellerInformationService: SellerInformationService,
    private localStorageService: LocalStorageService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.currentUser = this.localStorageService.getItem('user')!
    if (this.item && this.item.customerKey) {
      this.messageDisabled = (this.item.contactUserIds ?? []).length === 0 || this.item.contactUserIds![0] === this.currentUser.id
      this.contactSellerDisabled = this.item.contactUserIds?.includes(this.currentUser.id) ?? false
      this.sellerInformationService
        .getPublicSellerInformation(this.item.customerKey)
        .pipe(takeUntil(this.destroy$))
        .subscribe(information => {
          this.seller = information
        })
    }

    this.initBuyNowForm()
  }

  private initBuyNowForm() {
    if (this.item && this.item.availableQuantity) {
      this.contactSellerForm.controls.quantity.setValidators([Validators.required, Validators.min(1), Validators.max(this.item.availableQuantity)])
    }

    if (this.item && !this.item.shippingOptions) {
      this.contactSellerForm.removeControl('shippingOption')
    }

    if (this.item && this.item?.shippingOptions && this.item.shippingOptions?.length) {
      this.contactSellerForm.controls.shippingOption.setValue(this.item.shippingOptions[0])
    }

    this.contactSellerForm.updateValueAndValidity()
  }
}
