import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { IProductContentItem, IProductItem } from 'shared-lib'
import { SellerItemActions, SellerItemActionTypes } from './seller-item.actions'
import { Timestamp } from '@engineering11/web-api-firebase'

export interface State extends EntityState<IProductContentItem> {
  loadedItems: boolean
  selectedItemId: string | null
}

export const adapter: EntityAdapter<IProductContentItem> = createEntityAdapter<IProductContentItem>({
  selectId: productContentItem => productContentItem.contentId,
  sortComparer: (a: IProductContentItem, b: IProductContentItem) => {
    const aDate = (a.document as Timestamp<IProductItem>).__updatedAt ?? new Date()
    const bDate = (b.document as Timestamp<IProductItem>).__updatedAt ?? new Date()
    return bDate.getTime() - aDate.getTime()
  },
})

export const initialState: State = adapter.getInitialState({
  loadedItems: false,
  selectedItemId: null,
})

export function reducer(state = initialState, action: SellerItemActions) {
  switch (action.type) {
    case SellerItemActionTypes.getSellerItems:
      return { ...state }
    case SellerItemActionTypes.getSellerItemsSuccess:
      return adapter.setMany(action.payload, { ...state, loadedItems: true })
    case SellerItemActionTypes.selectItem:
      return { ...state, selectedItemId: action.payload }
    case SellerItemActionTypes.resetSelectedItem:
      return { ...state, selectedItemId: null }
    case SellerItemActionTypes.deleteItemSuccess:
      return adapter.removeOne(action.payload.contentId, state)
    case SellerItemActionTypes.updateItemSuccess:
    case SellerItemActionTypes.publishItemSuccess:
    case SellerItemActionTypes.delistItemSuccess:
      const existingItem = state.entities[action.payload.contentId] as IProductContentItem
      const updatedItem = { ...existingItem, ...action.payload }
      return adapter.upsertOne(updatedItem, { ...state })
    default:
      return state
  }
}
