// Framework
import { Component, OnInit, ViewChild } from '@angular/core'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class AppFooterComponent implements OnInit {
  @ViewChild('modalPrivacy') modalPrivacy: any

  statusPrivacy: boolean = false
  statusTerms: boolean = false
  currentYear = new Date().getFullYear()

  constructor() {}

  ngOnInit(): void {}

  setStatusModalPrivacy(value: boolean) {
    this.statusPrivacy = value
  }

  setStatusModalTerms(value: boolean) {
    this.statusTerms = value
  }
}
