import { Component } from '@angular/core'
import { E11Logger } from '@engineering11/web-api-error'

@Component({
  selector: 'lib-maintenance-page',
  template: ` <sdk-config-maintenance-page> </sdk-config-maintenance-page> `,
})
export class MaintenancePageComponent {
  constructor(private logger: E11Logger) {}
}
