import { IPurchasableItem } from '@engineering11/commerce-web'
import { IContentDocument, IContentItem } from '@engineering11/content-web'
import { IFileReceipt } from '@engineering11/files-web'
import { IGeoLocation } from '@engineering11/maps-web'
import { Modify, Optional } from '@engineering11/types'
import { IUomCode } from './uom.codes'

export const productItemContentType = 'product-item' as const
export type ProductItemContentType = typeof productItemContentType

// UNIT???
export interface IDimension {
  name: string
  value: number
  unitOfMeasurement: DimensionUnit
}

export interface ILocation {
  zipCode?: string
}

export interface IProductAttribute {
  name: string
  value: string
}

export enum ItemCondition {
  New = 'New',
  NewWithDefects = 'New - With Defects',
  UsedLikeNew = 'Used - Like New',
  UsedWithDefects = 'Used - With Defects',
}

export enum DimensionUnit {
  Inch = 'in',
  Centimeter = 'cm',
  Meter = 'm',
  Feet = 'ft',
}

export enum ItemStatus {
  Draft = 'Draft',
  Published = 'Published',
  Delisted = 'Delisted',
  SoldOut = 'Sold Out',
}

export enum ShippingOptions {
  Shipping = 'Shipping',
  Pickup = 'Pickup',
  Delivery = 'Delivery',
}

export enum ItemViewContext {
  Preview = 'preview',
  Listing = 'listing',
}

// A complete publishable product item
export interface ICompleteProductItem extends IContentDocument, IPurchasableItem {
  id: string // Making non-optional despite IContentDocument having optional id
  contentId: string
  contentType: ProductItemContentType
  status: ItemStatus
  category: string
  title: string
  dimensions?: IDimension[]
  condition?: ItemCondition
  longDescription?: string
  externalItemId?: string
  model?: string
  modelNumber?: string
  unitOfMeasure?: IUomCode
  classifications?: string[]
  keywords?: string[]
  manufacturer?: string
  brand?: string
  attributes?: IProductAttribute[]
  features?: string[]
  thumb?: IFileReceipt
  photos?: IFileReceipt[]
  video?: IFileReceipt
  contactUserIds: string[]
  material?: string
  _geoloc: IGeoLocation
  location: ILocation
  shippingOptions?: ShippingOptions[]
}

export type IDraftProductItem = Optional<
  ICompleteProductItem,
  'title' | 'amountInCents' | 'quantity' | 'category' | '_geoloc' | 'availableQuantity' | 'name' | 'location' | 'contactUserIds'
>

export type IProductItem = ICompleteProductItem | IDraftProductItem

export type IProductContentItem = IContentItem<IProductItem>
export type ICompleteProductContentItem = IContentItem<ICompleteProductItem>

export type AddableProductItem = Modify<
  Optional<IProductContentItem, 'contentId'>,
  {
    document: Optional<IProductItem, 'contentId' | 'id'>
  }
>
