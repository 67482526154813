<div class="container-view">
  <app-header></app-header>

  <main>
    <div class="e11-container e11-mx-auto e11-pt-6 e11-pb-12">
      <ui-notification-long></ui-notification-long>
    </div>
  </main>

  <app-footer></app-footer>
</div>
