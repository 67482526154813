<e11-modal
  #modalTerms
  modalWidth="medium"
  [bodyHeight]="'half'"
  title="{{ 'Terms of Use' | translate }}"
  [showHeader]="true"
  [showFooter]="false"
  (modalClosed)="closeModal.emit()"
>
  <ng-template e11Template="Body" class="e11-mb-8">
    <display-terms></display-terms>
    <div class="spacer-2rem"></div>
  </ng-template>
</e11-modal>
