import { Component, EventEmitter, Output } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { IGeoLocation, LocationService } from '@engineering11/maps-web'
import { US_ZIP_REGEX } from '@engineering11/utility'
import { E11ErrorHandlerService, E11Logger } from '@engineering11/web-api-error'
import { NotificationHelper } from '@engineering11/web-ui-helpers'
import { firstValueFrom } from 'rxjs'

export interface IZipCodeGeoLocation extends IGeoLocation {
  zipCode: string
}

@Component({
  selector: 'app-form-zip-code',
  template: `
    <form class="" [formGroup]="formZipCode" (ngSubmit)="onSubmitZipCode()" novalidate>
      <div class="e11-flex e11-items-end">
        <span class="control-custom e11-relative e11-w-full">
          <e11-input
            name="zipCode"
            [size]="'sm'"
            [placeholder]="'00000' | translate"
            id="id_zipCode"
            formControlName="zipCode"
            [parentForm]="formZipCode"
            [label]="'Zip Code' | translate"
          ></e11-input>
          <button class="btn-custom e11-bg-skin-primary-accent hover:e11-bg-skin-primary e11-cursor-pointer" type="submit">
            <ng-container *ngIf="loading">
              <e11-icon-loader [classOverrides]="'e11-text-white'"></e11-icon-loader>
            </ng-container>
            <!-- Set Location -->
            <span *ngIf="!loading" class="material-icons-outlined e11-text-white md-18"> place </span>
          </button>
        </span>
      </div>
    </form>
  `,
  styleUrls: ['./form-zip-code.component.scss'],
})
export class FormZipCodeComponent {
  @Output() zipCodeLocation = new EventEmitter<IZipCodeGeoLocation>()

  formZipCode = new UntypedFormGroup({
    zipCode: new UntypedFormControl('', [Validators.required, Validators.pattern(US_ZIP_REGEX)]),
  })
  loading = false

  constructor(
    private locationService: LocationService,
    private logger: E11Logger,
    private errorHandler: E11ErrorHandlerService,
    private notificationService: NotificationHelper
  ) {}

  async onSubmitZipCode() {
    const zipCode = this.formZipCode.value.zipCode
    this.logger.log('Zip Code Submitted', zipCode)

    if (!this.formZipCode.valid) return this.logger.log('invalid Zip Code') // TODO: Handle invalid

    this.loading = true
    try {
      const location = await firstValueFrom(this.locationService.getLocationFromPostcode(zipCode))
      const zipCodeLocation = { ...location, zipCode }
      this.logger.log('zipCodeLocation', zipCodeLocation)
      this.notificationService.popNotification({ title: 'Success', message: 'Found location from post code!', type: 'success' })
      this.zipCodeLocation.emit(zipCodeLocation)
    } catch (e: any) {
      this.errorHandler.handleError(e, { alertUser: true })
    }

    this.loading = false
  }

  reset() {
    this.formZipCode.reset(undefined, { emitEvent: false })
  }
}
