import { Component, inject } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { TermsService } from '@engineering11/user-web'
import { map, take } from 'rxjs'

@Component({
  selector: 'display-terms',
  template: `<div *ngrxLet="terms$ as tou">
    <p [innerHTML]="tou"></p>
  </div> `,
})
export class DisplayTermsOfUseComponent {
  _sanitizer = inject(DomSanitizer)
  termsService = inject(TermsService)
  terms$?: SafeHtml = this.termsService.getMostRecentTerms().pipe(
    take(1),
    map(terms => this._sanitizer.bypassSecurityTrustHtml(terms[0].terms))
  )
}
