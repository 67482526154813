import { Injectable } from '@angular/core'
import { IAuthConfig, BaseAuthConfigProvider } from '@engineering11/auth-web'
import { E11Logger } from '@engineering11/web-api-error'
import { Store } from '@ngrx/store'
import { environment } from '../../environments/environment'
import { AppConfigService } from '@engineering11/tenant-bootstrap-web'

@Injectable({ providedIn: 'root' })
export class AuthConfigProvider extends BaseAuthConfigProvider {
  constructor(private store: Store, private logger: E11Logger, private appConfig: AppConfigService) {
    super()
  }
  get config(): IAuthConfig {
    return {
      authBaseURL: environment.services.auth,
      tenantId: this.appConfig.config.auth.tenantId,
      bootstrapId: this.appConfig.config.id,
    }
  }
}
