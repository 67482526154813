import { Injectable } from '@angular/core'
import { environment } from '@consumer/environments/environment'
import {
  BaseConversationCustomDataProvider,
  IConversationVMNoCustomData,
  MessagingConfigurationProvider,
  MessagingMappingConfig,
} from '@engineering11/messaging-web'
import { Store } from '@ngrx/store'
import { getCurrentToken, getCurrentUserId, getCustomerKey$ } from 'shared-lib'

export const MESSAGING_EDITOR_CONFIG = {
  attachmentMaxFileSize: 100,
} as const

@Injectable({ providedIn: 'root' })
export class MessagingConfigurationStore {
  constructor(private store: Store) {}

  getConfig() {
    return {
      baseUrl: environment.services.messaging,
      baseRoute: '/c/m',
      token$: this.store.select(getCurrentToken),
      userId$: this.store.pipe(getCurrentUserId),
      messagingEditorConfig: {
        attachmentMaxFileSize: MESSAGING_EDITOR_CONFIG.attachmentMaxFileSize,
      },
    }
  }
}
@Injectable({ providedIn: 'root' })
export class MessagingConfigFromApp implements MessagingConfigurationProvider {
  constructor(private configStore: MessagingConfigurationStore) {}

  get config(): MessagingMappingConfig {
    return this.configStore.getConfig()
  }
}

@Injectable({ providedIn: 'root' })
export class ConversationCustomDataProvider extends BaseConversationCustomDataProvider {
  conversationSubtitle(conversation: IConversationVMNoCustomData): string {
    return conversation.clientMetadata?.productItemName
  }
}
