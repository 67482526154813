<e11-modal
  #modalPrivacy
  modalWidth="medium"
  [bodyHeight]="'half'"
  title="{{ 'Privacy Policy' | translate }}"
  [showHeader]="true"
  [showFooter]="false"
  (modalClosed)="closeModal.emit()"
>
  <ng-template e11Template="Body" class="e11-mb-8">
    <privacy-policy #privacyPolicy> </privacy-policy>
  </ng-template>
</e11-modal>
