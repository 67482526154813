import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { CloudflareStreamModule } from '@cloudflare/stream-angular'
import { ConfigModule } from '@engineering11/config-web'
import { NotificationHandlerModule, UiNotificationComponent, UiNotificationLongComponent } from '@engineering11/notifications-web'
import { E11BadgeModule } from '@engineering11/ui-lib/e11-badge'
import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import { E11ModalModule } from '@engineering11/ui-lib/e11-modal'
import { E11MultiSelectModule } from '@engineering11/ui-lib/e11-multi-select'
import { E11TextareaModule } from '@engineering11/ui-lib/e11-textarea'
import { LetDirective, PushPipe } from '@ngrx/component'
import { TranslateModule } from '@ngx-translate/core'

import { DeviceDetectorService } from 'ngx-device-detector'
import { UiComponentLibraryModule } from './_component-library/component-library.module'
import { AboutComponent } from './components/about/about.component'
import { DateDisplayStartEndComponent } from './components/date-display-start-end/date-display-start-end.component'
import { MaintenancePageComponent } from './components/maintenance-page/maintenance-page.component'
import { VersionCheckerComponent } from './components/version-checker/version-checker.component'
import { AppConfig } from './config'
import { ConfigProvider } from './config/config'
import { NotificationConfigProvider } from './config/notification.config'
import { ENVIRONMENT } from './constants'
import { IEnvironment, IPlatformAdminEnvironment } from './model/environment.model'
import { CentsToDollarsPipePipe } from './pipe/cents-to-dollars.pipe'
import { DeepCopyPipe } from './pipe/deep-copy.pipe'
import { ApplicationStatusPipe } from './pipe/enum.pipes'
import { MapPipe } from './pipe/map.pipe'
import { BrowserDetectionService } from './service/browser-detection.service'
import { ContentReportedNotificationHandler } from './service/notifications/content-reported-notification.handler'
import { UserMentionedNotificationHandler } from './service/notifications/user-mentioned-notification.handler'
import { SharedLibComponent } from './shared-lib.component'

const DeclareExportComponents = [
  SharedLibComponent,
  VersionCheckerComponent,
  DateDisplayStartEndComponent,

  // Pipes
  DeepCopyPipe,
  MapPipe,
  CentsToDollarsPipePipe,
  ApplicationStatusPipe,
  AboutComponent,
  MaintenancePageComponent,
]

@NgModule({
  declarations: [...DeclareExportComponents],
  imports: [
    CommonModule,
    FormsModule,

    E11ButtonModule,
    E11BadgeModule,

    E11ModalModule,
    E11TextareaModule,

    TranslateModule,
    RouterModule,
    ReactiveFormsModule,
    CloudflareStreamModule,
    LetDirective,
    PushPipe,
    NotificationHandlerModule.forRoot({
      notificationHandlers: [ContentReportedNotificationHandler, UserMentionedNotificationHandler],
      configProvider: NotificationConfigProvider,
    }),
    UiComponentLibraryModule,
    ConfigModule.forRoot({ configModel: AppConfig, configProvider: ConfigProvider }),
    E11MultiSelectModule,
  ],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initAppWithNotification,
    //   multi: true,
    //   deps: [ConfigurationStore, Store], // <-- Add this
    // },
    {
      provide: DeviceDetectorService,
    },
    BrowserDetectionService,
  ],
  exports: [...DeclareExportComponents, UiNotificationComponent, UiNotificationLongComponent],
})
export class SharedLibModule {
  public static forRoot(env: IEnvironment | IPlatformAdminEnvironment): ModuleWithProviders<SharedLibModule> {
    return {
      ngModule: SharedLibModule,
      providers: [{ provide: ENVIRONMENT, useValue: env }],
    }
  }
}
