import { Injectable } from '@angular/core'
import { ConversationSideOverService, MESSAGING_NOTIFICATION_IDS } from '@engineering11/messaging-web'
import { IAppNotificationVM, INotificationHandler, UserAppNotificationService } from '@engineering11/notifications-web'
import { UserMentionedNotification } from '../../model/app-notification.model'

@Injectable({ providedIn: 'root' })
export class UserMentionedNotificationHandler implements INotificationHandler<UserMentionedNotification, IAppNotificationVM> {
  constructor(private conversationSideOverService: ConversationSideOverService, private userAppNotificationService: UserAppNotificationService) {}
  notificationType = MESSAGING_NOTIFICATION_IDS.messaging_user_mentioned
  onClick(notification: UserMentionedNotification): boolean | Promise<boolean> {
    if (!notification.viewed) this.userAppNotificationService.markNotificationAsRead(notification.id)
    this.conversationSideOverService.open({ conversationId: notification.conversationId })
    return true
  }
}
