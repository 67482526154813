<div class="e11-w-full">
  <e11-button
    [display]="'block'"
    [size]="'sm'"
    value="{{ loading ? 'Getting location...' : ('Use My Location' | translate) }}"
    [icon]="'place'"
    [iconSize]="'md-18'"
    [iconPosition]="'left'"
    [color]="'primary-accent'"
    (click)="searchByMyLocation()"
  ></e11-button>
</div>
