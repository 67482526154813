import { Injectable } from '@angular/core'
import { BaseLocalStorageService } from '@engineering11/web-utilities'
import { IConsumerUser } from '@instockdepot/user-shared'

export interface ILocalStorage {
  user: IConsumerUser
  token: string
}

@Injectable({ providedIn: 'root' })
export class LocalStorageService extends BaseLocalStorageService<ILocalStorage> {}
