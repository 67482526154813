import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-header-unauthenticated',
  templateUrl: './header-unauthenticated.component.html',
  styleUrls: ['./header-unauthenticated.component.scss'],
})
export class AppHeaderUnauthenticatedComponent implements OnInit {
  @Input() cta: string = ''
  imgSrc: string = '../../../../assets/images/logo/isd_header_logo_179x79.png'

  constructor() {}

  ngOnInit() {}
}
