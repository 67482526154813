// Framework
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthenticationComponent } from './authentication.component'
import { AuthenticationLoginComponent } from './views/login/login.component'
import { canActivate } from '@angular/fire/compat/auth-guard'
import { redirectLoggedInToHome } from '@engineering11/auth-web'
import { FileNotFoundComponent } from '../_404/404.component'
import { AuthRecoveryFindAccountComponent } from './views/find-account/find-account.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth/login',
  },
  {
    path: 'auth',
    component: AuthenticationComponent,
    children: [
      {
        path: 'login',
        component: AuthenticationLoginComponent,
        ...canActivate(redirectLoggedInToHome('profile')),
      },
      {
        path: 'login/:deepLink',
        component: AuthenticationLoginComponent,
        ...canActivate(redirectLoggedInToHome('profile')),
      },
      // Account recover
      {
        path: 'recover',
        component: AuthRecoveryFindAccountComponent,
        ...canActivate(redirectLoggedInToHome('home')),
      },
      {
        path: '**',
        component: FileNotFoundComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
