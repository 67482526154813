import { Injectable } from '@angular/core'
import { IAppNotificationVM, INotificationHandler, UserAppNotificationService } from '@engineering11/notifications-web'
import { IContentReportedNotification } from '../../model/app-notification.model'
import { SellerNavigationService } from '../seller-navigation.service'

@Injectable({ providedIn: 'root' })
export class ContentReportedNotificationHandler implements INotificationHandler<IContentReportedNotification, IAppNotificationVM> {
  constructor(private sellerNavigationService: SellerNavigationService, private userAppNotificationService: UserAppNotificationService) {}
  notificationType = 'content-reported'
  onClick(notification: IContentReportedNotification): boolean | Promise<boolean> {
    if (!notification.viewed) {
      this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    return this.sellerNavigationService.toListing(notification.itemId)
  }
}
