import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core'
import { E11SideOverService, OverlaySizes } from '@engineering11/ui-lib/e11-side-over'

import { UiScrollLockingService } from '@consumer/app/services/scroll-locking.service'

@Component({
  selector: 'sideover-container',
  templateUrl: './sideover-container.component.html',
  styleUrls: ['./sideover-container.component.scss'],
})
export class SideoverContainerComponent implements OnInit {
  @Input() headerTitle = ''

  @Input() subTitle = ''

  @Input() size: OverlaySizes = 'md'
  @Input() showHeader: boolean = true
  @Input() showFooter: boolean = true
  @Input() showDeleteButton: boolean = false
  @Input() showSubmitButton: boolean = true
  @Input() showCloseButton: boolean = true

  @Input() loading: boolean = false
  @Input() submitDisabled: boolean = false // Consider reaching into the form in a standardised way

  @Output() removeItem = new EventEmitter()
  @Output() submitItem = new EventEmitter()
  @Output() sideoverClosed = new EventEmitter()

  @ViewChild('sideOverContent') sideOverContent!: TemplateRef<any>

  isOpen = false

  constructor(private sideOverService: E11SideOverService, private scrollLockService: UiScrollLockingService) {}

  ngOnInit(): void {}

  open() {
    this.isOpen = true
    this.sideOverService.overlayToggle(true, this.sideOverContent, { size: this.size })
    this.scrollLockService.scrollLock(true)
  }

  close() {
    this.sideOverService.overlayToggle(false)
    this.scrollLockService.scrollLock(false)
    // TODO: How to maintain the animation - setTimeout doesn't work because it allows the user to break their state by closing and quickly reopening
    this.isOpen = false // rip contents out of the DOM on close
    this.sideoverClosed.emit()
  }
}
