import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'modal-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class ModalTermsOfUseComponent implements OnInit {
  @ViewChild('modalTerms') modalTerms: any
  @Output() closeModal = new EventEmitter()

  _status: boolean
  get status(): boolean {
    return this._status
  }
  @Input() set status(value: boolean) {
    this._status = value
    if (this._status) this.modalTerms.open()
    else this.modalTerms?.close()
  }

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}
}
