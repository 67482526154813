<div class="e11-flex e11-items-center" [formGroup]="parentForm">
  <ng-container *ngFor="let option of item.shippingOptions">
    <e11-radio-input
      [radioValue]="option"
      [label]="option"
      [color]="'primary'"
      [displayInline]="true"
      [formControlName]="'shippingOption'"
      [name]="'shippingOption'"
      size="sm"
    ></e11-radio-input>
  </ng-container>

  <e11-tooltip
    [color]="'dark'"
    tooltipText="{{ 'The seller can offer delivery, pickup, or shipping. Shown here are the ones they offer for this listed item.' | translate }}"
  >
    <span class="material-icons-outlined md-18 e11-text-skin-primary">help</span>
  </e11-tooltip>
</div>
