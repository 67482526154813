import { Default, IFeatures, Json, BaseListenConfig, Boolean } from '@engineering11/config-web'

export interface AppFeatures extends IFeatures {
  messaging: boolean
  notifications: boolean
}

export const defaultFeatures: AppFeatures = {
  messaging: false,
  notifications: false,
}

export class AppConfig {
  @Boolean()
  @Default(false)
  fromRemoteConfig!: boolean

  @Json()
  @Default(defaultFeatures)
  features!: AppFeatures
}

export interface AppListenConfig extends BaseListenConfig {}
