import { AtLeast } from '@engineering11/types'
import { IUser } from '@engineering11/user-shared'
import { Action } from '@ngrx/store'
import { ICompleteProductContentItem, IProductContentItem } from 'shared-lib'

export enum SellerItemActionTypes {
  onInitSellerItems = '[Seller Effects] on init',
  getSellerItems = '[Seller Effects] get seller items',
  getSellerItemsSuccess = '[Seller Effects] get seller items success',
  selectItem = '[Items List] select item',
  selectItemById = '[Items List] select item by id',
  resetSelectedItem = '[View Item] reset selected item',
  createItem = '[Seller Items Page] create new item',
  createItemSuccess = '[Seller Effects] create new item success',
  updateItem = '[View Item Page] update item',
  updateItemSuccess = '[Seller Effects] update item success',
  publishItem = '[Edit Item sheet] publish item',
  publishItemSuccess = '[Seller Effects] publish item success',
  deleteItem = '[View Item page] delete item',
  deleteItemSuccess = '[Seller Effects] delete item success',
  delistItem = '[View Item page] delist item',
  delistItemSuccess = '[Seller Effects] delist item success',
  noSellerAction = '[Seller Effects] no seller action',
}

export class OnInitSellerItems implements Action {
  readonly type = SellerItemActionTypes.onInitSellerItems
  constructor() {}
}

export class GetSellerItems implements Action {
  readonly type = SellerItemActionTypes.getSellerItems
  constructor(public payload: IUser) {}
}

export class GetSellerItemsSuccess implements Action {
  readonly type = SellerItemActionTypes.getSellerItemsSuccess
  constructor(public payload: IProductContentItem[]) {}
}

export class SelectItem implements Action {
  readonly type = SellerItemActionTypes.selectItem
  constructor(public payload: string) {}
}

export class SelectItemById implements Action {
  readonly type = SellerItemActionTypes.selectItemById
  constructor(public payload: string) {}
}

export class ResetSelectedItem implements Action {
  readonly type = SellerItemActionTypes.resetSelectedItem
  constructor() {}
}

export class UpdateItem implements Action {
  readonly type = SellerItemActionTypes.updateItem
  constructor(public payload: AtLeast<IProductContentItem, 'contentId' | 'customerKey'>) {}
}

export class UpdateItemSuccess implements Action {
  readonly type = SellerItemActionTypes.updateItemSuccess
  constructor(public payload: AtLeast<IProductContentItem, 'contentId' | 'customerKey'>) {}
}

export class PublishItem implements Action {
  readonly type = SellerItemActionTypes.publishItem
  constructor(public payload: ICompleteProductContentItem) {}
}

export class PublishItemSuccess implements Action {
  readonly type = SellerItemActionTypes.publishItemSuccess
  constructor(public payload: ICompleteProductContentItem) {}
}

export class DeleteItem implements Action {
  readonly type = SellerItemActionTypes.deleteItem
  constructor(public payload: IProductContentItem) {}
}

export class DeleteItemSuccess implements Action {
  readonly type = SellerItemActionTypes.deleteItemSuccess
  constructor(public payload: IProductContentItem) {}
}

export class DelistItem implements Action {
  readonly type = SellerItemActionTypes.delistItem
  constructor(public payload: ICompleteProductContentItem) {}
}

export class DelistItemSuccess implements Action {
  readonly type = SellerItemActionTypes.delistItemSuccess
  constructor(public payload: ICompleteProductContentItem) {}
}

export class NoSellerAction implements Action {
  readonly type = SellerItemActionTypes.noSellerAction
  constructor() {}
}

export type SellerItemActions =
  | OnInitSellerItems
  | GetSellerItems
  | GetSellerItemsSuccess
  | SelectItem
  | SelectItemById
  | ResetSelectedItem
  | UpdateItem
  | UpdateItemSuccess
  | PublishItem
  | PublishItemSuccess
  | DeleteItem
  | DeleteItemSuccess
  | DelistItem
  | DelistItemSuccess
  | NoSellerAction
