import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import * as fromReduce from '@consumer/app/store/reducers'
import { IRegistrationResult } from '@engineering11/registration-web'
import { E11NotificationMessage, E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { Store } from '@ngrx/store'
import { LocalStorageService, OnLogIn } from 'shared-lib'

@Component({
  selector: 'app-register-invite',
  templateUrl: './register-invite.component.html',
  styleUrls: ['./register-invite.component.scss'],
})
export class RegisterInviteComponent implements OnInit {
  registrationCode?: string
  showError = false
  error: any
  loading = false

  constructor(
    private activatedRoute: ActivatedRoute,
    private localStorage: LocalStorageService,
    private store: Store<fromReduce.user.State>,
    private notificationsService: E11NotificationsService
  ) {}
  async ngOnInit() {
    this.registrationCode = this.activatedRoute.snapshot.params['code']
  }

  async onSubmit(result: IRegistrationResult) {
    this.loading = true
    this.localStorage.setItem('user', result.appUser)
    await new Promise(resolve => setTimeout(resolve, 1000))
    this.store.dispatch(new OnLogIn({ token: result.authToken, appUserId: result.appUser.id }))
    this.loading = false
  }

  onError(error: any): void {
    this.notificationsService.popNotificationMessage(notification)
  }
}

const notification: E11NotificationMessage = {
  title: 'An Error Occurred',
  message: `An error occurred and we are unable to process registration`,
  type: 'error',
  autoClose: true,
  dismissOnRouteChange: true,
}
