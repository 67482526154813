import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { ConsumerNavigationService, SEARCH_QUERY_PARAM } from '@consumer/app/services/consumer-navigation.service'
import { NGXLogger } from 'ngx-logger'
import { Subject } from 'rxjs'
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  formSearch = new UntypedFormGroup({})
  formSearchSubmitted = false

  searchCurrent = ''

  destroy$ = new Subject()
  searchQuery$ = this.route.queryParamMap.pipe(
    takeUntil(this.destroy$),
    map(params => params.get(SEARCH_QUERY_PARAM)),
    distinctUntilChanged()
  )

  constructor(
    private formBuilder: UntypedFormBuilder,
    private navigationService: ConsumerNavigationService,
    private logger: NGXLogger,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.createFormSearch()
    this.listenToSearchOnRoute()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  listenToSearchOnRoute() {
    this.searchQuery$.subscribe(searchCurrent => {
      this.searchCurrent = searchCurrent!
      this.formSearch.setValue({ search: searchCurrent })
    })
  }

  createFormSearch() {
    this.formSearch = this.formBuilder.group({
      search: new UntypedFormControl(''),
    })
    this.formSearch.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(changes => {
      this.searchCurrent = this.formSearch.value.search
    })
  }

  search() {
    const searchValue = this.formSearch.value.search
    this.navigationService.searchWith(searchValue)
  }
}
