export interface ISellerApplication {
  id: string
  name: string
  companyName: string
  companyWebsite?: string
  companyDescription?: string
  ein: string
  zipCode: string
  address: string
  address2?: string
  city: string
  state: string
  phoneNumber?: string
  status: SellerApplicationStatus
  customerKey: string
}

export enum SellerApplicationStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  NotFound = 'NotFound',
  RequirePaymentAccountOnboarding = 'RequirePaymentAccountOnboarding',
}

export type ISellerApplicationDto = Omit<ISellerApplication, 'id' | 'status' | 'customerKey'>
