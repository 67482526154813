<footer class="footer e11-bg-skin-app-bg e11-border-t-[32px] e11-border-skin-primary-accent e11-py-8">
  <div class="e11-container e11-mx-auto">
    <div class="e11-flex e11-grid e11-grid-cols-1 md:e11-grid-cols-2">
      <div class="e11-flex e11-mb-8 e11-mr-0 e11-justify-center md:e11-justify-start md:e11-mr-8 md:e11-mb-0">
        <img
          src="../../../../assets/images/logo/isd_header_logo_179x79.png"
          class="pointer"
          height="100"
          alt="{{ 'IN STOCK. ONLINE.' | translate }}"
          title="{{ 'IN STOCK. ONLINE.' | translate }}"
          aria-describedby="imgLogoinstockdepot"
        />
      </div>
      <div class="">
        <h3 class="e11-text-skin-dark">{{ 'IN STOCK. ONLINE.' | translate }}</h3>
        <p class="e11-mb-0">
          In Stock Depot is the only marketplace for the construction industry to buy and sell excess jobsite supplies and materials.
        </p>
      </div>
    </div>
  </div>

  <div class="e11-container e11-mx-auto">
    <div class="e11-w-full">
      <e11-divider [margin]="2"></e11-divider>
    </div>
  </div>

  <div class="e11-container e11-mx-auto">
    <div class="e11-flex e11-grid e11-grid-cols-1 md:e11-grid-cols-2">
      <div class="">
        <p class="e11-text-sm e11-text-skin-light e11-mb-0">© {{ currentYear }} In Stock Depot, LLC. - {{ 'All rights reserved' | translate }}</p>
      </div>

      <div class="e11-flex e11-items-center e11-justify-end e11-w-full">
        <span class="e11-mr-4 e11-cursor-pointer e11-text-skin-primary hover:e11-text-skin-primary-accent" (click)="setStatusModalPrivacy(true)">
          {{ 'Privacy Policy' | translate }}
        </span>
        <span class="e11-mr-4 e11-cursor-pointer e11-text-skin-primary hover:e11-text-skin-primary-accent" (click)="setStatusModalTerms(true)">
          {{ 'Terms of Use' | translate }}
        </span>
        <modal-privacy-policy [status]="statusPrivacy" (closeModal)="setStatusModalPrivacy(false)"></modal-privacy-policy>
        <modal-terms-of-use [status]="statusTerms" (closeModal)="setStatusModalTerms(false)"></modal-terms-of-use>
      </div>
    </div>
  </div>
</footer>
