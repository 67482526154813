import { toKebabCase } from '@engineering11/utility'
import { Env, ITenantConfig, PREFERRED_REGION, PRODUCTION_ENV, ServiceNames, envToProjectId } from '../model/environment.model'

const SINGLE_API_DOMAIN = 'api.instockdepot.com'

export interface ServicePathBuilder {
  build(serviceName: string): string
}

export class AppEnginePathBuilder implements ServicePathBuilder {
  constructor(private env: Env, private protocol = 'https') {}
  build(serviceName: ServiceNames) {
    const projectId = envToProjectId[this.env]
    const formattedName = toKebabCase(serviceName) // We rely on serviceName deterministically being the camelCase version of service-name
    return `${this.protocol}://${formattedName}-rest-dot-${projectId}.uc.r.appspot.com/${formattedName}` // 'https://jobs-rest-dot-cnect-dev.uc.r.appspot.com/jobs',
  }
}

export class CloudFunctionsPathBuilder implements ServicePathBuilder {
  constructor(private env: Env, private region = PREFERRED_REGION) {}
  /**
   * Builds Cloud Function invocation path for region and project.
   * @param serviceName IGNORED in this implementation
   * @returns built path
   */
  build(serviceName: string = ''): string {
    return `https://${this.region}-${envToProjectId[this.env]}.cloudfunctions.net`
  }
}

export class ApiGatewayPathBuilder implements ServicePathBuilder {
  constructor(private env: Env, private protocol = 'https') {}
  /** Will convert serviceName to service-name (camelCase to kebab-case) */
  build(serviceName: ServiceNames) {
    const formattedName = toKebabCase(serviceName) // We rely on serviceName deterministically being the camelCase version of service-name
    return `${this.protocol}://${getEnvDomain(this.env, SINGLE_API_DOMAIN)}/${formattedName}`
  }
}

export function buildTenantConfig(env: Env): ITenantConfig {
  return {
    appConfigBaseURL: `https://${getEnvDomain(env, 'content.instockdepot.com')}/assets/bootstrap`,
    defaultTenant: getEnvDomain(env, 'app.instockdepot.com'),
  }
}

/**
 * By standard, production env paths omit the env - this is the helper for implementing that
 */
function getEnvRoot(env: Env) {
  return env !== PRODUCTION_ENV ? `${env}-` : ''
}

export function getEnvDomain(env: Env, baseDomain: string): string {
  return `${getEnvRoot(env)}${baseDomain}`
}
