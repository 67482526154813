import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { SharedModule } from '../_shared/shared.module'
import { NotificationComponent } from './notification.component'
import { NotificationRoutingModule } from './notification-routing.module'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'

@NgModule({
  declarations: [NotificationComponent],
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, UiComponentLibraryModule, NotificationRoutingModule],
})
export class NotificationModule {}
