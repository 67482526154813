import { Injectable } from '@angular/core'
import { ContentItemService, IContentPublishLog, IContentPublishRequest } from '@engineering11/content-web'
import { IUser } from '@engineering11/user-shared'
import { deepCopy } from '@engineering11/utility'
import { AddableProductItem, ICompleteProductContentItem, ICompleteProductItem, IDraftProductItem, IProductContentItem, ItemStatus } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class ItemContentService extends ContentItemService<IProductContentItem> {
  /**
   * Creates a mock item without saving it right away. This prevents us having "dead" draft records when the user clicks "create" but never clicks save
   */
  createDraft(item: AddableProductItem): IProductContentItem {
    const documentItem = deepCopy(item)
    const document: IDraftProductItem = { ...documentItem.document } as IDraftProductItem
    return { ...documentItem, document } as IProductContentItem
  }

  async updateAndPublish(contentItem: ICompleteProductContentItem, user: IUser): Promise<ICompleteProductContentItem> {
    const status = ItemStatus.Published
    const document = { ...contentItem.document, status }
    const newItem = { ...contentItem, document }
    await this.persist(newItem)
    await this.createPublishRequest(newItem.document, user)
    return newItem
  }
  // TODO: Consider whether to manage status on back end
  async delistItem(item: ICompleteProductContentItem, user: IUser): Promise<ICompleteProductContentItem> {
    const status = ItemStatus.Delisted
    const document = { ...item.document, status }
    const newItem = { ...item, document }
    await this.update(newItem)
    await this.createPublishRequest(newItem.document, user)
    return newItem
  }

  private createPublishRequest(item: ICompleteProductItem, user: IUser) {
    const publishLog: IContentPublishRequest = {
      firstName: user.firstName,
      lastName: user.lastName,
      userId: user.id,
      document: {
        contentId: item.contentId,
        customerKey: user.customerKey,
      },
    }
    return this.publish.add(publishLog)
  }
}
