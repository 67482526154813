<nav class="e11-bg-skin-primary">
  <div class="e11-container e11-mx-auto">
    <div class="e11-flex e11-justify-between e11-h-16">
      <div class="e11-flex e11-items-center">
        <div [routerLink]="['/']" class="e11-flex-shrink-0 e11-flex e11-items-center e11-cursor-pointer">
          <img class="e11-block lg:e11-hidden e11-h-8 e11-w-auto" src="../../../../assets/images/logo/Main Logo-White.png" alt="IN STOCK DEPOT" />
          <img class="e11-hidden lg:e11-block e11-h-14 e11-w-auto" src="../../../../assets/images/logo/Main Logo-White.png" alt="IN STOCK DEPOT" />
        </div>
      </div>
      <div class="e11-grow">
        <app-search-bar></app-search-bar>
      </div>
      <div class="e11-hidden sm:e11-ml-6 sm:e11-flex sm:e11-items-center e11-relative">
        <ui-notification *ngIf="features?.notifications"> </ui-notification>
        <messages-envelope></messages-envelope>

        <!-- Profile dropdown -->
        <div class="e11-ml-3">
          <div>
            <e11-profile-pic
              class="e11-cursor-pointer"
              *ngIf="currentUser$ | async as currentUser"
              [imageUrl]="photoURL"
              [size]="'sm'"
              [textValue]="getInitial(currentUser.displayName || '')"
              (click)="dropDownOpen = !dropDownOpen"
            ></e11-profile-pic>
          </div>
          <div
            [ngClass]="{
              'e11-transition e11-ease-out e11-duration-200 e11-transform e11-opacity-0 e11-scale-95 e11-invisible': !dropDownOpen,
              'e11-transition e11-ease-in duration-75 e11-e11-transform e11-opacity-100 e11-scale-100': dropDownOpen
            }"
            class="e11-origin-top-right e11-absolute e11-right-0 e11-mt-2 e11-w-48 e11-rounded-md e11-shadow-lg e11-py-1 e11-bg-white e11-ring-1 e11-ring-black e11-ring-opacity-5 focus:e11-outline-none focus:e11-ring-opacity-100"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabindex="-1"
          >
            <a
              *ngFor="let item of availableNavigation"
              [routerLink]="[item.link]"
              routerLinkActive
              #rla="routerLinkActive"
              class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-skin-primary-accent/20"
              role="menuitem"
              tabindex="-1"
            >
              {{ item.name }}
            </a>
            <a
              [routerLink]="['/account']"
              class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 e11-cursor-pointer hover:e11-bg-skin-primary-accent/20"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-0"
            >
              {{ 'My Account' | translate }}
            </a>

            <a
              *ngIf="canViewBilling$ | async"
              [routerLink]="['/billing']"
              class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 e11-cursor-pointer hover:e11-bg-skin-primary-accent/20"
              role="menuitem"
              tabindex="-1"
            >
              {{ 'Billing' | translate }}
            </a>
            <a
              href="#"
              class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 e11-cursor-pointer hover:e11-bg-skin-primary-accent/20"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-2"
              (click)="signOut()"
            >
              {{ 'Sign out' | translate }}
            </a>
          </div>
        </div>
        <a
          href="javascript:void(0)"
          (click)="dropDownOpen = !dropDownOpen"
          *ngIf="currentUser$ | async as currentUser"
          class="sm:e11-visible e11-px-3 e11-py-2 e11-rounded-md e11-text-sm e11-font-medium e11-text-gray-200 hover:e11-text-white"
        >
          {{ currentUser.firstName }} {{ currentUser.lastName }}
        </a>
      </div>
      <div class="e11--mr-2 e11-flex e11-items-center sm:e11-hidden">
        <ui-notification *ngIf="features?.notifications"> </ui-notification>
        <messages-envelope></messages-envelope>

        <!-- Mobile menu button -->
        <button
          (click)="dropDownOpen = !dropDownOpen"
          type="button"
          class="e11-inline-flex e11-items-center e11-justify-center e11-p-2 e11-rounded-md e11-text-gray-100 hover:e11-text-gray-100 focus:e11-outline-none focus:e11-ring-2 focus:e11-ring-offset-2"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span class="e11-sr-only">Open main menu</span>
          <svg
            class="e11-block e11-h-6 e11-w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <svg
            class="e11-hidden e11-h-6 e11-w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <div class="sm:e11-hidden" [ngClass]="{ 'e11-hidden': !dropDownOpen }" id="e11-mobile-menu">
    <div class="e11-pt-4 e11-pb-3 e11-border-t e11-border-gray-200">
      <div class="e11-flex e11-items-center e11-px-4">
        <div class="e11-flex-shrink-0">
          <e11-profile-pic
            *ngIf="currentUser$ | async as currentUser"
            [imageUrl]="photoURL"
            [size]="'md'"
            [textValue]="getInitial(currentUser.displayName || '')"
            (click)="dropDownOpen = !dropDownOpen"
          ></e11-profile-pic>
        </div>
        <div class="e11-ml-3">
          <div class="e11-text-base e11-font-medium e11-text-gray-200" *ngIf="currentUser$ | async as currentUser">
            {{ currentUser.firstName }} {{ currentUser.lastName }}
          </div>
          <div class="e11-text-sm e11-font-medium e11-text-gray-300" *ngIf="currentUser$ | async as currentUser">{{ currentUser.email }}</div>
        </div>
      </div>
      <div class="e11-mt-3 e11-space-y-1">
        <a
          *ngFor="let item of availableNavigation"
          [routerLink]="[item.link]"
          routerLinkActive
          #rla="routerLinkActive"
          class="e11-block e11-px-4 e11-py-2 e11-text-base e11-font-medium e11-text-gray-200 hover:e11-text-gray-100"
        >
          {{ item.name }}
        </a>
        <a [routerLink]="['/account']" class="e11-block e11-px-4 e11-py-2 e11-text-base e11-font-medium e11-text-gray-200 hover:e11-text-gray-100">
          {{ 'My Account' | translate }}
        </a>
        <a
          *ngIf="canViewBilling$ | async"
          [routerLink]="['/billing']"
          class="e11-block e11-px-4 e11-py-2 e11-text-base e11-font-medium e11-text-gray-200 hover:e11-text-gray-100"
          role="menuitem"
          tabindex="-1"
        >
          {{ 'Billing' | translate }}
        </a>
        <a href="#" class="e11-block e11-px-4 e11-py-2 e11-text-base e11-font-medium e11-text-gray-200 hover:e11-text-gray-100" (click)="signOut()">
          {{ 'Sign out' | translate }}
        </a>
      </div>
    </div>
  </div>
</nav>

<e11-loader-dots
  [size]="'md'"
  [color]="'e11-bg-skin-white'"
  [fontColor]="'e11-text-skin-white'"
  label="{{ 'One moment please...' | translate }}"
  [fullScreen]="true"
  [hasBackdrop]="true"
  [backdropStyle]="'dark'"
  [active]="loading"
></e11-loader-dots>
