import { createFeatureSelector, createSelector } from '@ngrx/store'
import { adapter, State } from './seller-item.reducer'

const sellerItemEntitySelectors = adapter.getSelectors()

export const getSellerItemStore = createFeatureSelector<State>('sellerItem')

export const getSellerItems = createSelector(getSellerItemStore, sellerItemEntitySelectors.selectAll)
export const getSellerItemDict = createSelector(getSellerItemStore, sellerItemEntitySelectors.selectEntities)

export const sellerItemsLoaded = createSelector(getSellerItemStore, store => store.loadedItems)

export const getSelectedItem = createSelector(getSellerItemStore, store => {
  const entityDict = store.entities
  const selectedId = store.selectedItemId
  return selectedId && entityDict && entityDict[selectedId] ? entityDict[selectedId] : null
})

export const getSellerItemById = (id: string) => createSelector(getSellerItemDict, entities => entities[id])
