import { CustomerPermissions } from '@engineering11/customer-web'
import { PaymentPermissions } from '@engineering11/payment-web'

export enum Permission {
  SELL_ITEMS = 'SELL_ITEMS',
  MANAGE_USERS = 'MANAGE_USERS',
}

/**
 * All recognised permissions should be noted in this type
 */
export type AllPermissions = Permission | CustomerPermissions | PaymentPermissions
