import { DefaultCustomerRoles } from '@engineering11/customer-web'
import { ADMINISTRATOR_ROLE } from 'shared-lib'

export type AllRoles = DefaultCustomerRoles

export const ALL_ROLES: AllRoles[] = [ADMINISTRATOR_ROLE].concat(Object.values(DefaultCustomerRoles)) as AllRoles[]

export const RoleDescriptionMap: Record<AllRoles, string> = {
  // [ADMINISTRATOR_ROLE]: 'The Administrator role has access to all features including user management and billing.',
  [DefaultCustomerRoles.ClientEditor]: 'Can edit clients',
  [DefaultCustomerRoles.ClientViewer]: 'Can view clients',
  [DefaultCustomerRoles.TenantAdmin]: 'Full administrative powers for client management',
}

export const RoleLabelMap: Record<AllRoles, string> = {
  // [EMPLOYER_USER_ROLES.ADMINISTRATOR]: 'Administrator',
  [DefaultCustomerRoles.ClientEditor]: 'Client Editor',
  [DefaultCustomerRoles.ClientViewer]: 'Client Viewer',
  [DefaultCustomerRoles.TenantAdmin]: 'Tenant Admin',
}

export function roleToSelectOption(role: AllRoles) {
  return { label: RoleLabelMap[role], value: role, description: RoleDescriptionMap[role] }
}
