// Framework
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { mappingToFormSelect, States, Time } from '@engineering11/web-utilities'

@Injectable({
  providedIn: 'root',
})
export class FormSelectService {
  pleaseSelect = ''
  selectLevel = ''
  pleaseSelectState: string = 'Please select' // to be translated if needed

  constructor(private translate: TranslateService) {
    translate.get(this.pleaseSelectState).subscribe((res: string) => {
      this.pleaseSelectState = res
    })
    translate.get(this.pleaseSelectState).subscribe((res: string) => {
      this.pleaseSelect = res
    })
    translate.get(this.pleaseSelectState).subscribe((res: string) => {
      this.selectLevel = res
    })
  }

  getAddressStates() {
    return mappingToFormSelect({ ...States, '': this.pleaseSelectState })
  }

  getTimeList() {
    return mappingToFormSelect(Time)
  }
}
