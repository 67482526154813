<div class="e11-flex e11-items-center e11-justify-center e11-h-16 e11-pl-8">
  <form class="e11-w-full" [formGroup]="formSearch" (ngSubmit)="search()" novalidate>
    <e11-input
      [placeholder]="'Search for items' | translate"
      [parentForm]="formSearch"
      formControlName="search"
      [size]="'md'"
      [autofocus]="true"
      labelColor="secondary"
      [leadingIcon]="'search'"
    ></e11-input>
  </form>
</div>
