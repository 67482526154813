import { Component, OnInit, ViewChild } from '@angular/core'
import { IRegistrationResult } from '@engineering11/registration-web'
import { Store } from '@ngrx/store'
import { LocalStorageService } from 'shared-lib'
import { PRIMARY_OUTLET, Router, UrlTree } from '@angular/router'
import * as fromReduce from '@consumer/app/store/reducers'
import { E11ModalComponent } from '@engineering11/ui-lib/e11-modal'

@Component({
  selector: 'app-registration-single-step',
  templateUrl: './registration-single-step.component.html',
  styleUrls: ['./registration-single-step.component.scss'],
})
export class RegistrationSingleStepComponent implements OnInit {
  @ViewChild('modalTerms') modalTerms!: E11ModalComponent

  constructor(private localStorageService: LocalStorageService, private store: Store<fromReduce.user.State>, private router: Router) {}

  ngOnInit(): void {}

  async onSubmit(result: IRegistrationResult) {
    // This is here to avoid a race condition?
    this.localStorageService.setItem('token', result.authToken)
    this.localStorageService.setItem('user', result.appUser)
    /**
     * The below checks if there is a deep link on the route, if so set
     * the redirect to that. The onboarding chain should always propagate
     * the deep link forward
     */
    const urlTree: UrlTree = this.router.parseUrl(this.router.url)
    const primary = urlTree.root.children[PRIMARY_OUTLET]
    let redirectPath = ''
    if (primary.segments.length > 1) {
      redirectPath = decodeURIComponent(primary.segments[primary.segments.length - 1].path)
    }

    if (result) {
      this.router.navigate([redirectPath], { queryParamsHandling: 'merge' })
    }
  }

  termsClicked() {
    this.modalTerms.open()
  }

  navigateToLogin() {
    this.router.navigate(['/auth/login'], { queryParamsHandling: 'merge' })
  }
}
