<div class="container-view">
  <app-header></app-header>
  <main>
    <div class="e11-container e11-mx-auto">
      <router-outlet></router-outlet>
    </div>
  </main>

  <app-footer></app-footer>
</div>
