// Framework
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import { E11ChartPercentageModule } from '@engineering11/ui-lib/e11-chart-percentage'
import { E11DataTableModule } from '@engineering11/ui-lib/e11-datatable'
import { E11PanelModule } from '@engineering11/ui-lib/e11-panel'
import { E11TextareaModule } from '@engineering11/ui-lib/e11-textarea'

const ImportExportModules = [E11ButtonModule, E11ChartPercentageModule, E11DataTableModule, E11TextareaModule, E11PanelModule]

@NgModule({
  declarations: [],
  imports: [CommonModule, ...ImportExportModules],
  exports: [...ImportExportModules],
})
export class UiComponentLibraryModule {}
