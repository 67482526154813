// Framework
import { Component, OnInit } from '@angular/core'
import { NavigationEnd, PRIMARY_OUTLET, Router, UrlTree } from '@angular/router'

@Component({
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  backgroundCycling = true // Enables background image cycling.
  backgroundMax: number = 5
  backgroundRnd: number = 1 // Default login background with no image cycling
  // header: string = 'landing' // Headers need to change based on route - make 1 header more dynamic (multiple dumb headers or 1 smart)
  isLoading: boolean = true

  constructor(private router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const urlTree: UrlTree = this.router.parseUrl(this.router.url)
        const primary = urlTree.root.children[PRIMARY_OUTLET]
        let page = primary.segments[1]?.path || 'home'

        if (page === 'register') {
          // this.header = 'login'
          if (this.backgroundCycling) {
            this.backgroundRnd = this.getRandomNumberBetween(1, this.backgroundMax)
          }
        } else if (page === 'register-apply') {
          this.backgroundRnd = 0
        } else {
          // this.header = 'landing'
          this.backgroundRnd = 1
        }
      }
    })
  }

  ngOnInit(): void {
    this.loadImages()
  }

  getRandomNumberBetween(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  backgroundImages = [
    'https://content.instockdepot.com/assets/login/1.jpg',
    'https://content.instockdepot.com/assets/login/2.jpg',
    'https://content.instockdepot.com/assets/login/3.jpg',
    'https://content.instockdepot.com/assets/login/4.jpg',
    'https://content.instockdepot.com/assets/login/5.jpg',
  ]
  loadedImages = 0

  loadImages() {
    for (let i = 0; i < this.backgroundImages.length; i++) {
      let img = new Image()
      img.onload = () => {
        this.loaded()
      }
      img.src = this.backgroundImages[i]
    }
  }

  loaded() {
    this.loadedImages++
    //
    if (this.backgroundImages.length == this.loadedImages) {
      //all images loaded
      this.isLoading = false
      //
    }
  }
}
