// Framework
import { Component } from '@angular/core'

@Component({
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent {
  constructor() {}
}
