import { Component, OnInit } from '@angular/core'
import { selectors } from '@consumer/app/store/selectors'
import { IAppNotification } from '@engineering11/notifications-web'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  paginationSettings = {
    enabled: true,
    pageLinks: 5,
    rowCount: 25,
    serverSide: false,
    rowsPerPageOptions: [25, 50, 100],
  }
  constructor(private store: Store) {}

  ngOnInit(): void {}
}
