import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { E11BackdropModule } from '@engineering11/ui-lib/e11-backdrop'
import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import { E11InputCheckboxModule } from '@engineering11/ui-lib/e11-input-checkbox'
import { E11CarouselModule } from '@engineering11/ui-lib/e11-carousel'
import { E11ConfirmationModule } from '@engineering11/ui-lib/e11-confirmation'
import { E11DividerModule } from '@engineering11/ui-lib/e11-divider'
import { E11DataTableModule } from '@engineering11/ui-lib/e11-datatable'
import { E11IconModule } from '@engineering11/ui-lib/e11-icon'
import { E11ImageModule } from '@engineering11/ui-lib/e11-image'
import { E11ImagesModule } from '@engineering11/ui-lib/e11-images'
import { E11InputModule } from '@engineering11/ui-lib/e11-input'
import { E11LoaderModule } from '@engineering11/ui-lib/e11-loader'
import { E11LoaderDotsModule } from '@engineering11/ui-lib/e11-loader-dots'
import { E11ModalModule } from '@engineering11/ui-lib/e11-modal'
import { E11PanelModule } from '@engineering11/ui-lib/e11-panel'
import { E11PhoneNumberModule } from '@engineering11/ui-lib/e11-phone-number'
import { E11ProfilePicModule } from '@engineering11/ui-lib/e11-profile-pic'
import { E11SelectModule } from '@engineering11/ui-lib/e11-select'
import { E11SideOverModule } from '@engineering11/ui-lib/e11-side-over'
import { E11SwitchModule } from '@engineering11/ui-lib/e11-switch'
import { E11TextareaModule } from '@engineering11/ui-lib/e11-textarea'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'
import { E11TabsModule } from '@engineering11/ui-lib/e11-tabs'
import { E11AutocompleteModule } from '@engineering11/ui-lib/e11-autocomplete'
import { E11BadgeModule } from '@engineering11/ui-lib/e11-badge'
import { E11EmptyStateModule } from '@engineering11/ui-lib/e11-empty-state'
import { E11InputLabelModule } from '@engineering11/ui-lib/e11-input-label'
import { E11InputErrorsModule } from '@engineering11/ui-lib/e11-input-errors'
import { E11MultiSelectModule } from '@engineering11/ui-lib/e11-multi-select'
import { E11PaginatorModule } from '@engineering11/ui-lib/e11-paginator'
import { E11PopoverModule } from '@engineering11/ui-lib/e11-popover'
import { E11RadioInputModule } from '@engineering11/ui-lib/e11-radio-input'

const ImportExportModules = [
  E11BadgeModule,
  E11ButtonModule,
  E11DataTableModule,
  E11DividerModule,
  E11InputCheckboxModule,
  E11InputLabelModule,
  E11TextareaModule,
  E11CarouselModule,
  E11TooltipModule,
  E11ProfilePicModule,
  E11PanelModule,
  E11LoaderDotsModule,
  E11LoaderModule,
  E11ConfirmationModule,
  E11BackdropModule,
  E11SelectModule,
  E11PhoneNumberModule,
  E11ImageModule,
  E11SideOverModule,
  E11IconModule,
  E11TabsModule,
  E11InputErrorsModule,
  E11EmptyStateModule,
  E11ImagesModule,
  E11AutocompleteModule,
  E11PaginatorModule,
  E11MultiSelectModule,
  E11RadioInputModule,
  E11ModalModule,
  E11PopoverModule,
  E11SwitchModule,
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...ImportExportModules,
    E11InputModule.forRoot({
      maxLengthByContext: {},
      numericWheelStrategy: 'blur',
    }),
  ],
  exports: [...ImportExportModules, E11InputModule],
})
export class UiComponentLibraryModule {}
