import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AnalyticsService } from '@engineering11/web-api-firebase'

@Component({
  templateUrl: './find-account.component.html',
  styleUrls: ['./find-account.component.scss'],
})
export class AuthRecoveryFindAccountComponent implements OnInit {
  constructor(private analyticsService: AnalyticsService, private router: Router) {}

  ngOnInit(): void {}

  continueToLogin() {
    this.router.navigate(['shop'])
  }
  onSubmit() {}
}
