import { Component, OnInit } from '@angular/core'

@Component({
  template: ` <div class="e11-container e11-mx-auto e11-mb-16">
    <div class="e11-grid e11-grid-cols-2 e11-mb-4">
      <div class="e11-col-span-1">
        <h2 class="e11-text-skin-primary e11-mb-2">{{ 'Company Account Information' | translate }}</h2>
      </div>
    </div>
    <div>
      <e11-tabs [selectedTabId]="'companyInformation'">
        <e11-tab [title]="'Company Information' | translate" [tabId]="'companyInformation'" [contentClassOverrides]="'e11-py-4'">
          <app-company-information> </app-company-information>
        </e11-tab>
        <e11-tab [title]="'Manage Users' | translate" [tabId]="'manageUsers'" [contentClassOverrides]="'e11-py-4'">
          <app-manage-users></app-manage-users>
        </e11-tab>
        <e11-tab [title]="'Account Deactivation' | translate" [tabId]="'accountSecurity'" [contentClassOverrides]="'e11-py-4'">
          <e11-panel>
            <h3>{{ 'Account Deactivation' | translate }}</h3>
            <p>{{ 'Deactivate this account' | translate }}</p>
            <sdk-registration-deregister></sdk-registration-deregister>
          </e11-panel>
        </e11-tab>
      </e11-tabs>
    </div>
  </div>`,
})
export class CustomerDetailComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
