import { Injectable } from '@angular/core'
import { BaseAccessConfigProvider, IAccessConfig } from '@engineering11/access-web'
import { isDeepEqual, isNotNil } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { Store } from '@ngrx/store'
import { distinctUntilChanged, filter, map } from 'rxjs/operators'
import { getCurrentUser, getCurrentUserId } from 'shared-lib'
import { ALL_ROLES, RoleDescriptionMap, RoleLabelMap } from '../role.constants'

@Injectable({ providedIn: 'root' })
export class AccessConfigProvider extends BaseAccessConfigProvider {
  private roles$ = this.store.select(getCurrentUser).pipe(
    filter(isNotNil),
    map(user => user.roles),
    distinctUntilChanged(isDeepEqual)
  )
  constructor(private store: Store, private logger: E11Logger) {
    super()
  }

  get config(): IAccessConfig {
    return {
      roles$: this.roles$,
      userId$: this.store.pipe(getCurrentUserId),
      useLimitDefaults: {},
      roleDisplayConfig: { allRoles: ALL_ROLES, roleDescriptionMap: RoleDescriptionMap, roleLabelMap: RoleLabelMap },
    }
  }
}
