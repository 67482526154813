import { Inject, Injectable } from '@angular/core'
import { E11ToastService, ToastDismissType } from '@engineering11/ui-lib/e11-toast'
import { ILogger, LOGGER_TOKEN } from '@engineering11/web-api-error'
import { ViewportService, ViewPortSize } from '@engineering11/web-utilities'
export type ToastCTA = 'resendVerificationEmail'

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(private toastService: E11ToastService, private viewportService: ViewportService, @Inject(LOGGER_TOKEN) private logger: ILogger) {}

  verificationEmailToast() {
    const viewport = this.viewportService.getCurrentSize()
    const smallerSizes: ViewPortSize[] = ['xs', 'sm', 'md']
    const small = smallerSizes.includes(viewport)
    const cta: ToastCTA = 'resendVerificationEmail'
    // Todo: Translate these...
    this.toastService.popToastMessage({
      title: 'As a reminder...',
      bodyTitle: 'Verify your email now to claim your account!',
      bodyContent: small ? '' : 'It is very important that we verify who you are and that you have access to the email you provided.',
      type: '',
      dismissType: ToastDismissType.User,
      dismissText: 'Later',
      cta,
      ctaText: 'Resend verification email',
      icon: 'mail_outline',
      dismissOnRouteChange: false,
      callback: () => this.logger.log('Toast: Resend verification email callback'),
    })
  }

  closeToast() {
    this.toastService.closeToast()
  }
}
