import { Pipe, PipeTransform } from '@angular/core'
import { SellerApplicationStatus } from '../model/seller-application.model'

export const applicationStatusMappings: { [key in SellerApplicationStatus]: string } = {
  [SellerApplicationStatus.Accepted]: 'Accepted',
  [SellerApplicationStatus.NotFound]: 'Not Found',
  [SellerApplicationStatus.Pending]: 'Pending',
  [SellerApplicationStatus.Rejected]: 'Rejected',
  [SellerApplicationStatus.RequirePaymentAccountOnboarding]: 'Payment Account Required',
}

@Pipe({ name: 'applicationStatus' })
export class ApplicationStatusPipe implements PipeTransform {
  transform(value: SellerApplicationStatus): string {
    return applicationStatusMappings[value]
  }
}
