export interface IServices {
  auth: string
  cloudFunctions: string
  commerce: string
  content: string
  customer: string
  events: string
  files: string
  integration: string
  messaging: string
  notifications: string
  product: string
  productItem: string
  registration: string
  seller: string
  user: string
}

export interface IPlatformAdminServices {
  auth: string
  files: string
  notifications: string
  product: string
  registration: string
  user: string
  customer: string
}

export interface IConsumerServices extends IServices {}

export type ServiceNames = keyof (IServices & IPlatformAdminServices)

export interface ITenantConfig {
  appConfigBaseURL: string
  defaultTenant: string
}

export interface IEnvironment {
  algoliaAppId: string
  algoliaSearchKey?: string // TODO: make not optional when we have more envs
  authentication: boolean
  basePlatformHost: string
  cdnCname: string
  emailVerificationEmailTemplateId: string
  env: string
  firebaseConfig: any
  passwordRecoveryEmailTemplateId: string
  production: boolean
  services: IServices
  stripePublishableKey: string
  tenant: ITenantConfig
  tenantId?: string
  themesEnabled: boolean
  v2ReCaptcha: string
  version: string
}

export interface IConsumerEnvironment extends IEnvironment {
  services: IConsumerServices
  _business_EmailActionRoute: string
}

export interface IPlatformAdminEnvironment {
  algoliaAppId: string
  algoliaSearchKey: string
  authentication: boolean
  basePlatformHost: string
  cdnCname: string
  env: string
  firebaseConfig: any
  production: boolean
  services: IPlatformAdminServices
  stripePublishableKey: string
  tenantId: string
  themesEnabled: boolean
  v2ReCaptcha: string
  version: string
}

export type Env = 'dev' | 'ci' | 'qa' | 'stage' | 'demo' | 'production'

/**
 * Index to production **Env**.
 * If type (and not just value) needs to be 'production', please document why.
 */
export const PRODUCTION_ENV: Env = 'production'

/**
 * Most of our resources prefer to be in same GCP region.
 */
export const PREFERRED_REGION = 'us-central1'

/**
 * KLUDGE. Might now always be 1-to-1 mapping environment name to projectId.
 */
export type ProjectId = 'isd-dev-100' | 'isd-ci-100' | 'isd-qa-100' | 'isd-stage-100' | 'isd-demo-100' | 'isd-prod-100'

/**
 * KLUDGE. Might now always be 1-to-1 mapping environment name to projectId.
 */
export const envToProjectId: Record<Env, ProjectId> = {
  dev: 'isd-dev-100',
  ci: 'isd-ci-100',
  qa: 'isd-qa-100',
  stage: 'isd-stage-100',
  demo: 'isd-demo-100',
  production: 'isd-prod-100',
}
