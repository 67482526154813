<div class="e11-w-full e11-px-6 e11-w-full md-e11-w-2/3 lg:e11-w-1/2 e11-mx-auto e11-pt-8 e11-pb-16">
  <e11-panel [bgOpacity]="90" [contentClassOverrides]="'e11-p-6 e11-pb-12'">
    <h2 class="e11-text-center e11-text-skin-primary">
      {{ "Let's find your account" | translate }}
    </h2>
    <p class="e11-text-sm e11-text-center">
      {{ 'Please enter the email address associated with your account' | translate }}
    </p>
    <auth-forgot-password (formSubmitted)="onSubmit()" (continueClicked)="continueToLogin()"></auth-forgot-password>
    <div class="e11-w-full e11-text-righ e11-mt-2 e11-text-right">
      <a [routerLink]="['/auth/login']" class="e11-text-sm e11-text-skin-primary-accent hover:e11-text-skin-primary e11-cursor-pointer">
        {{ 'Return to Login' | translate }}
      </a>
    </div>
  </e11-panel>
</div>
