import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core'
import { Permission } from '@consumer/app/config/permission.config'
import { INav } from '@consumer/app/interface/nav.interface'
import { selectors } from '@consumer/app/store/selectors'
import { PermissionStore } from '@engineering11/access-web'
import { PaymentPermissions } from '@engineering11/payment-web'
import { e11Log } from '@engineering11/web-api-error'
import { Store, select } from '@ngrx/store'
import { NGXLogger } from 'ngx-logger'
import { Observable, Subject } from 'rxjs'
import { takeUntil, tap } from 'rxjs/operators'
import { AppFeatures, IAppConsumerUser, OnLogOut } from 'shared-lib'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  photoURL: any = null
  currentUser$: Observable<IAppConsumerUser | null> = this.store.pipe(select(selectors.getCurrentUser))

  features?: AppFeatures

  dropDownOpen = false

  availableNavigation = new Array<INav>()
  sellMenuAvailable = false
  adminMenuAvailable = false
  loading = false

  canViewBilling$ = this.permissionStore.hasPermission(PaymentPermissions.ViewBillingDetails)

  destroy$ = new Subject()

  constructor(private store: Store, private permissionStore: PermissionStore, private logger: NGXLogger, private elementRef: ElementRef) {
    this.permissionStore
      .hasPermission(Permission.SELL_ITEMS)
      .pipe(takeUntil(this.destroy$))
      .subscribe(allowed => {
        if (allowed && !this.sellMenuAvailable) {
          this.availableNavigation.push(
            ...[
              {
                name: 'Manage Listings',
                isLabel: false,
                router: true,
                icon: 'people-arrows',
                permissions: [Permission.SELL_ITEMS],
                subs: [],
                notification: 0,
                link: '/sell/for-sale',
              },
            ]
          )
          this.sellMenuAvailable = true
        }
      })
    this.permissionStore
      .hasPermission(Permission.MANAGE_USERS)
      .pipe(takeUntil(this.destroy$))
      .subscribe(allowed => {
        if (allowed && !this.adminMenuAvailable) {
          this.availableNavigation.push({
            name: 'Company Settings',
            isLabel: false,
            router: true,
            icon: 'people-arrows',
            permissions: [Permission.MANAGE_USERS],
            subs: [],
            notification: 0,
            link: '/customer',
          })
          this.adminMenuAvailable = true
        }
      })
  }

  ngOnInit() {
    this.store
      .select(selectors.getFeatures)
      .pipe(
        takeUntil(this.destroy$),
        tap(features => this.logger.log({ features }))
      )
      .subscribe(features => (this.features = features))
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  signOut(): void {
    this.store.dispatch(new OnLogOut())
  }

  getInitial(value: string) {
    return value.match(/\b(\w)/g)?.join('') || ''
  }

  // Closers - Coffee is for closers only
  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: any[]) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement)
    if (!clickedInside) {
      this.dropDownOpen = false
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dropDownOpen = false
    }
  }
}
