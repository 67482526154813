import { Injectable } from '@angular/core'
import { NavigationExtras, Router } from '@angular/router'

export const SELLER_ROUTES = Object.freeze({
  VIEW_EDIT_ITEMS: 'edit-item',
})

@Injectable({ providedIn: 'root' })
export class SellerNavigationService {
  constructor(private router: Router) {}

  toItemDetail(contentId: string, routerOptions?: NavigationExtras) {
    return this.router.navigate(['sell', SELLER_ROUTES.VIEW_EDIT_ITEMS, contentId], routerOptions)
  }

  toListings() {
    return this.router.navigate(['sell/for-sale'])
  }

  toListing(itemId: string) {
    return this.router.navigate(['sell/for-sale', itemId])
  }
}
