import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ROUTES } from '@consumer/app/services/consumer-navigation.service'
import { sellerApplicationStatus } from '@consumer/app/store/seller-application/seller-application.selectors'
import { Store } from '@ngrx/store'
import { SellerApplicationStatus } from 'shared-lib'

@Component({
  selector: 'app-payment-banner',
  template: `
    <ng-container *ngrxLet="sellerApplicationStatus$ as sellerApplicationStatus">
      <div
        *ngIf="sellerApplicationStatus == sellerApplicationStatusEnum.RequirePaymentAccountOnboarding"
        class="e11-w-full e11-flex e11-items-center"
      >
        <div class="e11-w-1/2 e11-text-right e11-border-r e11-border-skin-black e11-pr-8">
          <h1 class="e11-font-black e11-text-4xl e11-text-skin-primary-accent">{{ 'Complete Subscription' | translate }}</h1>
        </div>
        <div class="e11-w-1/2 e11-pl-8">
          <p>{{ 'Enter payment information and complete plan selection' | translate }}</p>
          <e11-button color="secondary" [value]="'Continue' | translate" (click)="paymentAccountRegistration()"></e11-button>
        </div>
      </div>
    </ng-container>
  `,
})
export class PaymentBannerComponent implements OnInit {
  sellerApplicationStatus$ = this.store.select(sellerApplicationStatus)
  sellerApplicationStatusEnum = SellerApplicationStatus
  constructor(private router: Router, private store: Store) {}
  ngOnInit() {}

  paymentAccountRegistration() {
    this.router.navigate([ROUTES.SUBSCRIPTION])
  }
}
