import { Action } from '@ngrx/store'
import { ISellerApplication, ISellerApplicationDto } from 'shared-lib'
import { Timestamp } from '@engineering11/web-api-firebase'

export enum SellerApplicationActionTypes {
  onInitSellerApplication = '[Seller Application Effects] on init',
  getSellerApplication = '[Seller Application Effects] get seller application',
  getSellerApplicationSuccess = '[Seller Application Effects] get seller application success',
  sendSellerApplication = '[Seller Application Page] send application',
  sendSellerApplicationSuccess = '[Seller Application Effects] send application success',
  sendSellerApplicationFailure = '[Seller Application Effects] send application failure',
}

export class OnInitSellerApplication implements Action {
  readonly type = SellerApplicationActionTypes.onInitSellerApplication
  constructor() {}
}

export class ListenSellerApplication implements Action {
  readonly type = SellerApplicationActionTypes.getSellerApplication
  constructor() {}
}

export class GetSellerApplicationSuccess implements Action {
  readonly type = SellerApplicationActionTypes.getSellerApplicationSuccess
  constructor(public payload: { application: Timestamp<ISellerApplication> }) {}
}

export class SendSellerApplication implements Action {
  readonly type = SellerApplicationActionTypes.sendSellerApplication
  constructor(public payload: ISellerApplicationDto) {}
}

export class SendSellerApplicationSuccess implements Action {
  readonly type = SellerApplicationActionTypes.sendSellerApplicationSuccess
  constructor() {}
}

export class SendSellerApplicationFailure implements Action {
  readonly type = SellerApplicationActionTypes.sendSellerApplicationFailure
  constructor() {}
}

export type SellerApplicationActions =
  | SendSellerApplication
  | SendSellerApplicationSuccess
  | SendSellerApplicationFailure
  | ListenSellerApplication
  | GetSellerApplicationSuccess
