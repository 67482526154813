import { createFeatureSelector, createSelector } from '@ngrx/store'
import { State } from './seller-application.reducer'

export const getSellerApplicationStore = createFeatureSelector<State>('sellerApplication')

export const sellerApplicationLoading = createSelector(getSellerApplicationStore, store => store.loading)
export const sellerApplicationSubmitLoading = createSelector(getSellerApplicationStore, store => store.loadingSubmit)

export const sellerApplicationStatus = createSelector(getSellerApplicationStore, store => store.applicationStatus)
export const sellerApplication = createSelector(getSellerApplicationStore, store => store.application)
