// Framework
import { Component, OnInit } from '@angular/core'
import { NavigationEnd, PRIMARY_OUTLET, Router, UrlTree } from '@angular/router'

@Component({
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit {
  header: string = 'landing' // Headers need to change based on route - make 1 header more dynamic (multiple dumb headers or 1 smart)
  isLoading: boolean = true

  constructor(private router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const urlTree: UrlTree = this.router.parseUrl(this.router.url)
        const primary = urlTree.root.children[PRIMARY_OUTLET]
        let page = primary.segments[1]?.path || 'home'

        if (page === 'login' || 'recover') {
          this.header = 'login'
        } else {
          this.header = 'landing'
        }
      }
    })
  }

  ngOnInit(): void {}
}
