import { Injectable, inject } from '@angular/core'
import { Router } from '@angular/router'
import { CustomRegistrationService } from '@consumer/app/services/custom-registration.service'
import { SellerService } from '@consumer/app/services/seller.service'
import { isNotNil } from '@engineering11/utility'
import { E11ErrorHandlerService, e11Log } from '@engineering11/web-api-error'
import { Timestamp } from '@engineering11/web-api-firebase'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { NGXLogger } from 'ngx-logger'
import { MonoTypeOperatorFunction, Observable, combineLatest } from 'rxjs'
import { filter, map, switchMap, tap } from 'rxjs/operators'
import { ISellerApplication, NotificationTranslateService, SellerApplicationStatus, getCurrentUser } from 'shared-lib'
import {
  GetSellerApplicationSuccess,
  ListenSellerApplication,
  OnInitSellerApplication,
  SellerApplicationActionTypes,
  SendSellerApplication,
  SendSellerApplicationFailure,
  SendSellerApplicationSuccess,
} from './seller-application.actions'
import { sellerApplicationStatus } from './seller-application.selectors'

@Injectable()
export class SellerApplicationEffects {
  customRegistrationService = inject(CustomRegistrationService)
  user$ = this.store.select(getCurrentUser).pipe(filter(isNotNil))
  sellerApplicationStatus$ = this.store.select(sellerApplicationStatus)

  constructor(
    private actions$: Actions,
    private store: Store,
    private sellerService: SellerService,
    private router: Router,
    private notificationsService: NotificationTranslateService,
    private logger: NGXLogger,
    private errorHandler: E11ErrorHandlerService
  ) {}

  ngrxOnInitEffects(): Action {
    return { type: SellerApplicationActionTypes.onInitSellerApplication }
  }

  onInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OnInitSellerApplication>(SellerApplicationActionTypes.onInitSellerApplication),
      map(action => new ListenSellerApplication())
    )
  })

  #regenerateCustomTokenForAcceptedApplication(): MonoTypeOperatorFunction<Timestamp<ISellerApplication>> {
    return (applicationStream: Observable<Timestamp<ISellerApplication>>) =>
      combineLatest([applicationStream, this.sellerApplicationStatus$]).pipe(
        map(([application, currentStatus]) => {
          if (shouldRegenerateToken(application, currentStatus ?? undefined)) this.customRegistrationService.regenerateCustomToken()
          return application
        })
      )
  }
  $onListenSellerApplication = createEffect(() =>
    this.actions$.pipe(
      ofType<ListenSellerApplication>(SellerApplicationActionTypes.getSellerApplication),
      switchMap(() => this.sellerService.getSellerApplication()),
      e11Log({ key: 'application' }),
      this.#regenerateCustomTokenForAcceptedApplication(),
      map(application => new GetSellerApplicationSuccess({ application }))
    )
  )

  $onSendSellerApplication = createEffect(() =>
    this.actions$.pipe(
      ofType<SendSellerApplication>(SellerApplicationActionTypes.sendSellerApplication),
      switchMap(action =>
        this.sellerService
          .sendSellerApplication(action.payload)
          .then(result => {
            this.logger.log('successfully submitted', result)
            this.router.navigate(['/billing/subscription'])
            this.notificationsService.popNotificationMessage(
              'Success!',
              'Congratulations! Your business has been approved, please subscribe to begin listing items.',
              'success',
              false
            )
            return new SendSellerApplicationSuccess()
          })
          .catch(error => {
            this.errorHandler.handleError(error, { alertUser: true })
            return new SendSellerApplicationFailure()
          })
      )
    )
  )
}

function shouldRegenerateToken(application?: ISellerApplication, currentStatus?: SellerApplicationStatus) {
  return application && currentStatus && currentStatus !== application.status && application.status === SellerApplicationStatus.Accepted
}
