import { ICustomClaims } from '@engineering11/auth-web'
import { AtLeast } from '@engineering11/types'
import { Timestamp } from '@engineering11/web-api-firebase'
import { Action } from '@ngrx/store'
import { IPlatformUser } from '../../model/user.model'

export enum UserActionTypes {
  onInitUser = '[User Initial App Load] on init',
  onInitUserSuccess = '[User Initial App Load] on init success',
  onIdTokenChanged = '[User] on Id token changed',
  onUserDocumentChanged = '[User] on user document changed',
  updateUserPhoto = '[User] update user photo',
  updateUser = '[User] update user',
  updateUserSuccess = '[User Effects] update user success',
  logIn = '[User From Component] on login',
  logInSuccess = '[User From Effect] on login success',
  logOut = '[User] on logout',
  logOutSuccess = '[User] on logout success',
  onRegistration = '[Password create page] on registration', // Triggered post-registration success
  onRegistrationSuccess = '[User effects] on registration success',
  reset = '[User] search users reset',
  error = '[User] error',
  noAction = '[User] no action',
}

export class OnInitUser implements Action {
  readonly type = UserActionTypes.onInitUser
  constructor(public payload: any) {}
}

export class OnInitUserSuccess implements Action {
  readonly type = UserActionTypes.onInitUserSuccess
  constructor(public payload: ICustomClaims) {}
}

export class OnIdTokenChanged implements Action {
  readonly type = UserActionTypes.onIdTokenChanged
  constructor(public payload: string) {}
}

export class OnUserDocumentChanged implements Action {
  readonly type = UserActionTypes.onUserDocumentChanged
  constructor(public payload: Timestamp<IPlatformUser> | null) {}
}

export class UpdateUser implements Action {
  readonly type = UserActionTypes.updateUser
  constructor(public payload: AtLeast<IPlatformUser, 'id'>) {}
}
export class UpdateUserPhoto implements Action {
  readonly type = UserActionTypes.updateUserPhoto
  constructor(public payload: { id: string; photoURL: string | null }) {}
}

export class OnRegistration implements Action {
  readonly type = UserActionTypes.onRegistration
  constructor(public payload: { appUserId: string; queryParams: { [key: string]: string } }) {}
}

export class OnRegistrationSuccess implements Action {
  readonly type = UserActionTypes.onRegistrationSuccess
  constructor() {}
}

export class OnLogIn implements Action {
  readonly type = UserActionTypes.logIn
  constructor(public payload: { token: string; appUserId: string; redirectPath?: string }) {}
}
export class OnLogInSuccess implements Action {
  readonly type = UserActionTypes.logInSuccess
  constructor(public payload: { currentUser: Timestamp<IPlatformUser>; redirectPath?: string }) {}
}

export class OnLogOut implements Action {
  readonly type = UserActionTypes.logOut
}

export class OnLogOutSuccess implements Action {
  readonly type = UserActionTypes.logOutSuccess
  constructor(public payload: any) {}
}

export class Reset implements Action {
  readonly type = UserActionTypes.reset
  constructor(public payload: any) {}
}

export class ErrorAction implements Action {
  readonly type = UserActionTypes.error
  constructor(public payload?: any) {}
}

export class NoAction implements Action {
  readonly type = UserActionTypes.noAction
  constructor(public payload?: any) {}
}

export type UserActions =
  | OnIdTokenChanged
  | OnUserDocumentChanged
  | UpdateUser
  | UpdateUserPhoto
  | Reset
  | ErrorAction
  | OnInitUser
  | OnInitUserSuccess
  | OnRegistration
  | OnRegistrationSuccess
  | OnLogIn
  | OnLogInSuccess
  | OnLogOut
  | OnLogOutSuccess
  | NoAction
