import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RegistrationConfigProvider } from '@consumer/app/config/registration.config'
import { RegistrationModule as RegistrationModuleSDK } from '@engineering11/registration-web'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { SharedModule } from '../_shared/shared.module'
import { RegistrationComponent } from './registration.component'
import { RegistrationRoutingModule } from './registration.routing'
import { RegisterInviteComponent } from './views/register-invite/register-invite.component'
import { RegistrationSingleStepComponent } from './views/registration-single-step/registration-single-step.component'

@NgModule({
  declarations: [RegistrationComponent, RegistrationSingleStepComponent, RegisterInviteComponent],
  imports: [
    CommonModule,
    SharedModule,
    RegistrationRoutingModule,
    UiComponentLibraryModule,
    FormsModule,
    ReactiveFormsModule,
    RegistrationModuleSDK.forRoot({ configProvider: RegistrationConfigProvider }),
  ],
  exports: [],
  providers: [],
})
export class RegistrationModule {}
