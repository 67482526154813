// Framework
import { Component, inject } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { PrivacyPolicyService } from '@engineering11/user-web'
import { map, take } from 'rxjs'

@Component({
  selector: 'privacy-policy',
  template: `<div *ngrxLet="policy$ as policy">
    <p [innerHTML]="policy"></p>
  </div> `,
})
export class PublicPrivacyComponent {
  _sanitizer = inject(DomSanitizer)
  privacyPolicyService = inject(PrivacyPolicyService)
  policy$?: SafeHtml = this.privacyPolicyService.getMostRecentPolicy().pipe(
    take(1),
    map(policy => this._sanitizer.bypassSecurityTrustHtml(policy.policy))
  )
}
