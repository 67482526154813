import { Injectable } from '@angular/core'
import { compareKeyDesc, sort } from '@engineering11/utility'
import { e11Log } from '@engineering11/web-api-error'
import { CollectionRepository, FirestoreQueryBuilder, Timestamp } from '@engineering11/web-api-firebase'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { COLLECTIONS, ISellerApplication } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class SellerApplicationRepository extends CollectionRepository<ISellerApplication> {
  protected COLLECTION = COLLECTIONS.SELLER_APPLICATION

  listenByCustomerKey(customerKey: string): Observable<Timestamp<ISellerApplication> | undefined> {
    const queryBuilder = new FirestoreQueryBuilder<Timestamp<ISellerApplication>>().where('customerKey', '==', customerKey)
    const result = this.queryValueChanges(queryBuilder.build())
    // order by __createdAt desc to get the latest application first
    return result.pipe(map(result => sort(result, compareKeyDesc('__createdAt'))[0]))
  }
}
