import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ICompleteProductItem } from 'shared-lib'

@Component({
  selector: 'ui-alg-card',
  templateUrl: './ui-alg-card.component.html',
  styleUrls: ['./ui-alg-card.component.scss'],
})
export class UiAlgCardComponent {
  @Input() public item!: ICompleteProductItem
  @Output() itemClick = new EventEmitter()

  constructor() {}
}
